import React, { Component } from 'react';

import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import AppSidebar from './AppSidebar';

class AccessDenied extends Component {

  constructor(props) {
    super(props);
  } 

  render = () => {
    var firebase = this.props.firebase;
    var user = firebase.user ? firebase.user.email: "";

    return (
      <div className='App-pageframeOuter'>
        <div className='App-pageframeInner'>
          <AppHeader firebase={firebase} />
          <AppSidebar tab="Home" firebase={firebase}/>
          <div className='App-content'>
            <div className='App-content-column'>
              <div className='permissionDeniedMessage'>
                User ({user}) does not have Lasoo Admin access rights       
              </div>
            </div>
          </div>
          <AppFooter tab="Home"/>
        </div>
      </div>
    )
  }
}

export default AccessDenied;