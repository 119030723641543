import React, { Component } from 'react';
import TabBar from './TabBar';

class AppFooter extends Component {
    constructor(props) {
      super(props);
   }
    
    render() {
      var tab = this.props.tab;
      var history = this.props.history;

      return (
        <div className="App-footer">
           <TabBar tab={tab} history={history}/>
        </div>
      );
    }
}

export default AppFooter;