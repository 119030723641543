import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Firebase from './Firebase';
import 'firebase/firestore';

import AccessDenied from './AccessDenied'
import AddTeamMember from './AddTeamMember'
import Content from './Content'
import EditNudge from './EditNudge'
import EditLevel from './EditLevel'
import Home from './Home'
import Levels from './Levels'
import Login from './Login'
import Nudges from './Nudges'
import Profile from './Profile'
import Settings from './Settings'
import Switcher from './Switcher'
import Team from './Team'

class AppRouter extends Component {
   constructor(props) {
      super(props);

      this.firebase = new Firebase((user) => {
         this.forceUpdate();
      });
   }

   render() {
      return (
         <Router>
            <Routes>
               <Route path="/" exact element={<Home firebase={this.firebase}/>}/>
               <Route path="/access-denied" exact element={<AccessDenied firebase={this.firebase}/>}/>               <Route path="/add-team-member" exact element={<AddTeamMember firebase={this.firebase}/>}/>
               <Route path="/add-team-member" exact element={<AddTeamMember firebase={this.firebase}/>}/>
               <Route path="/content" exact element={<Content firebase={this.firebase}/>}/>
               <Route path="/add-nudge" element={<EditNudge firebase={this.firebase}/>}/>
               <Route path="/edit-nudge/:id" element={<EditNudge firebase={this.firebase}/>}/>

               <Route path="/add-level" element={<EditLevel firebase={this.firebase}/>}/>
               <Route path="/edit-level/:id" element={<EditLevel firebase={this.firebase}/>}/>

               <Route path="/login" exact element={<Login firebase={this.firebase}/>}/>
               <Route path="/levels" exact element={<Levels firebase={this.firebase}/>}/>
               <Route path="/profile" exact element={<Profile firebase={this.firebase}/>}/>
               <Route path="/nudges" exact element={<Nudges firebase={this.firebase}/>}/>
               <Route path="/settings" exact element={<Settings firebase={this.firebase}/>}/>
               <Route path="/switch" exact element={<Switcher firebase={this.firebase}/>}/>
               <Route path="/team" exact element={<Team firebase={this.firebase}/>}/>
            </Routes>    
         </Router>
      );
   }
}

export default AppRouter;