import logo from './logo.svg';

import './index.scss';
import './App.scss';


import React, { Component } from 'react';
import { render } from 'react-dom';

import AppRouter from './AppRouter'

class App extends Component {
  render = () => {
    return (  
       <AppRouter/>
    );
  }
}

export default App;
