import React, { Component } from 'react';

const INITIAL_STATE = {

};

class Avatar extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  } 

  getInitials = () => {
    return (this.props.name) ? this.props.name.split(" ").map((n)=>n[0]).join("") : "";
  }

  getPhotoURL = () => {
    return this.props.image;
  }

  getClassName = () => {
    if (this.props.size && this.props.size === 'large')
      return "avatarLarge"
    else if (!this.props.size || this.props.size === 'medium')
      return "avatar";
    else
      return "avatarSmall"
  }

  render = () => {
    var photoURL = this.getPhotoURL();
    var initials = this.getInitials();

    return ( 
      <div className={this.getClassName()}>
        {photoURL ? 
          <img referrerpolicy="no-referrer" className={this.getClassName()} src={photoURL}/> :
          initials
        }
        
      </div>
    )
  }
}

export default Avatar;