import React, { Component } from 'react';

import Button from './Button';
import DataGrid, { SelectCellFormatter } from 'react-data-grid';
import RowComparator from './RowComparator'

import { Navigate } from "react-router-dom";

const defaultSortColumns = [ { columnKey: 'points', direction: 'ASC' } ];

class LevelsList extends Component {
  constructor(props) {
    super(props);

    this.defaultRowComparator = new RowComparator(defaultSortColumns);
    this.state = { 
      'rows' : this.getRows(this.props.team)
    };
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (JSON.stringify(this.props.enabledUsersOnly) !== JSON.stringify(prevProps.enabledUsersOnly)) {
      this.setState({ 'rows' : this.getRows(this.props.team) });
    }
  }

  getRows = (team) => {
    if (!team.levels)
      return [];

    var rows = []

    for (var levelId of Object.keys(team.levels)) {
        rows.push(this.getRow(levelId, team)) 
    }


    rows.sort(this.defaultRowComparator.compare)

    for (var i = 0; i < rows.length; i++) {
      rows[i].level = i+1;
    }

    return rows;
  }

  isContentAdmin = (memberEmail, team) => {
    return team['content-admin'] && team['content-admin'].indexOf(memberEmail) >= 0;
  }

  isTeamAdmin = (memberEmail, team) => {
    return team['team-admin'] && team['team-admin'].indexOf(memberEmail) >= 0;
  }

  getRow = (levelId, team) => {
    var row = {
      id : levelId
    }

    row.name = team.levels[levelId].name 
    row.points = team.levels[levelId].points 
 
    return row
  }
  
  setSortColumns = (sortColumnArray) => {
    var sortColumns = sortColumnArray.concat(defaultSortColumns)
    var rowComparator = new RowComparator(sortColumns)

    this.state.rows.sort(rowComparator.compare)
    this.setState({ 'rows' : this.state.rows })

    return true;
  }

  addClicked = () => {
    this.setState({ "navigateUrl": "/add-level" })
  }

  editClicked = (levelId) => {
    this.setState({ "navigateUrl": "/edit-level/" + levelId })
  }

  renderDataGrid = () => {
    var that = this;

    const columns = [
      { key: 'level', name: 'Level', sortable: true, width: 60, headerCellClass: 'headerCell', resizable: true },
      { key: 'name', name: 'Name', sortable: true, width: 240, headerCellClass: 'headerCell', resizable: true },
      { key: 'points', name: 'Points', sortable: true, width: 60, headerCellClass: 'headerCell', resizable: true },
    
      { key: '', name: '', sortable: true, width: 80, headerCellClass: 'headerCell', cellClass: 'cellCenterAlign', resizable: true,
        formatter({ row, onRowChange, isCellSelected }) {
          return <div>
            <Button action='Edit' type='secondary' size='small' onClick={() => that.editClicked(row.id)}>Edit</Button>
          </div>
        }
      } 
    ];

    return (<div className='dataGridContainer'><DataGrid className='rdg-light' style={{height: '100%', fontSize: 12 }} onSortColumnsChange={this.setSortColumns} columns={columns} onRowsChange={this.onRowsChange} rows={this.state.rows} /></div>);
  }

  render = () => {
    if (this.state.navigateUrl)
      return (<Navigate to={this.state.navigateUrl} replace={true} />)

    var elements = [];

    if (this.state.rows.length > 0) {
      elements.push(this.renderDataGrid())
    }
    else {
      elements.push(<p>No levels found</p>)
    }

    elements.push(<Button type='secondary' size='small' action='Add Level' onClick={this.addClicked}/>)

    return elements;
  }
}

export default LevelsList; 

