import React, { Component } from 'react';

import { Bar } from 'react-chartjs-2';

import moment from 'moment';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const calendarDateFormat = 'YYYY-MM-DD';

class ActivityByWeekChart extends Component {
  constructor(props) {
    super(props);

    // https://us-central1-lasoo-d9fad.cloudfunctions.net/getUsersReport?team=
  }

  calculateWeeksAndActivity = (rows) => {
    var today = moment();
    
    // Returns 1-7 where 1 is Monday, 7 is Sunday
    var dayOfWeek = today.isoWeekday()
    var startOfWeek = (dayOfWeek === 7) ? today : today.subtract(dayOfWeek, 'days');

    var weeklyActivityCounts = {};
    for (var c = 1; c <= 12; c++) {
      weeklyActivityCounts[startOfWeek.format(calendarDateFormat)] = 0
      startOfWeek = startOfWeek.subtract(7, 'days')
    }

    for (var row of rows) {
      var rowDate = moment(row.date, calendarDateFormat)
      var rowDayOfWeek = rowDate.isoWeekday()
      var rowStartOfWeekFormatted = (rowDayOfWeek === 7) ? rowDate.format(calendarDateFormat) : rowDate.subtract(rowDayOfWeek, 'days').format(calendarDateFormat);

      if (rowStartOfWeekFormatted in weeklyActivityCounts)
        weeklyActivityCounts[rowStartOfWeekFormatted] = weeklyActivityCounts[rowStartOfWeekFormatted] + 1
    }

    var weeks = []
    var activity = []
    
    for (var startOfWeek of Object.keys(weeklyActivityCounts).sort()) {
      weeks.push(moment(startOfWeek, calendarDateFormat).format('D MMM yy'))
      activity.push(weeklyActivityCounts[startOfWeek])
    }

    return {
      weeks: weeks,
      activity: activity
    }
  }

  render = () => {
    var weeksAndActivity = this.calculateWeeksAndActivity(this.props.rows)

    const options = {
   //   indexAxis: 'y',
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'right',
        },
        title: {
          display: true,
          text: 'HABIT CHECKINS BY WEEK',
        },
      },
    };

    const dataHorBar = {
      labels: weeksAndActivity.weeks,
      datasets: [
        {
          label: 'Goal count',
          backgroundColor: 'rgba(234, 41, 82, 0.5)',
          borderColor: '#ea2952',

          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: weeksAndActivity.activity
        }
      ]
    };

    return <div className='activityByWeekChart'><Bar options={options} data={dataHorBar} /></div>
  }
}

export default ActivityByWeekChart; 

