import React, { Component } from 'react';

import { Bar } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


class GoalsHabitsChart extends Component {
  constructor(props) {
    super(props);

    // https://us-central1-lasoo-d9fad.cloudfunctions.net/getUsersReport?team=
  }

  calculateHabitAndCounts = (rows) => {
    var habitCounts = {}

    for (var row of rows) {
      if (habitCounts[row.habit])
        habitCounts[row.habit] += 1
      else
        habitCounts[row.habit] = 1
    }

    var habitsAndCounts = [];
    for (var habit of Object.keys(habitCounts)) {
      habitsAndCounts.push({
        habit: habit,
        count: habitCounts[habit]
      })
    }

    habitsAndCounts.sort((v1, v2) => {
      if (v1.count > v2.count)
        return -1;
      else if (v1.count < v2.count)
        return 1;
      else if (v1.habit < v2.habit)
        return -1;
      else 
        return 1;
    })

    var habits = []
    var counts = [] 
    var count = 0;

    for (var habitAndCount of habitsAndCounts) {
      if (count < 9) {
        habits.push(habitAndCount.habit)
        counts.push(habitAndCount.count)
        count += 1;
      }
    }

    return {
      habits: habits,
      counts: counts
    }
  }

  render = () => {
    var habitsAndCounts = this.calculateHabitAndCounts(this.props.rows);

    const options = {
      indexAxis: 'y',
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'right',
        },
        title: {
          display: true,
          text: 'TOP HABITS',
        },
      },
    };

    const dataHorBar = {
      labels: habitsAndCounts.habits,
      datasets: [
        {
          label: 'Goal count',
          backgroundColor: 'rgba(234, 41, 82, 0.5)',
          borderColor: '#ea2952',

          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: habitsAndCounts.counts
        }
      ]
    };

    return <Bar options={options} data={dataHorBar} />
  }
}

export default GoalsHabitsChart; 

