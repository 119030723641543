import React, { Component } from 'react';

import { ref, child, get } from "firebase/database";
import { doc, getDoc } from "firebase/firestore";
import { Navigate } from "react-router-dom";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import AppSidebar from './AppSidebar';
import LoadingSpinner from './LoadingSpinner'
import PointsSettings from './PointsSettings';
import LevelsList from './LevelsList';
import WelcomeForm from './WelcomeForm'

const INITIAL_STATE = {
  dataLoaded: false,
};

class Levels extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  componentWillUnmount() {
  }

  isAdmin = (user, team) => {
    if (user.email === 'antony@lasoohabits.com' || user.email === 'g.kid@icloud.com' || user.email === 'sally@lasoohabits.com' || user.email === 'keith.abbott@adlerianconsulting.com.au' || user.email === 'david.royle@adlerianconsulting.com.au')
      return true; 

    if (!team['team-admin'])
      return false;

    return team['team-admin'].indexOf(user.email) >= 0;
  }

  checkDataLoaded = () => {
    var data = null;
    if (this.props.firebase.user && !this.state.dataLoaded) {
      const dbRef = ref(this.props.firebase.database);

      get(child(dbRef, 'users/' + this.props.firebase.user.uid)).then((snapshot) => {
        data = snapshot.val();

        return getDoc(doc(this.props.firebase.firestore, "teams", data.team));
      })
      .then((teamSnapshot) => {
        if (teamSnapshot.exists()) {

          var team = teamSnapshot.data()

          if (this.isAdmin(this.props.firebase.user, team)) {
            this.setState({
              data: data,
              dataLoaded: true,
              team: team
            });
          }
          else {
            this.setState({
              navigateUrl: "/access-denied"
            });
          }
        }
      })
    }
  }

  renderTabs = () => {
    return (<Tabs>
      <TabList>
        <Tab>Levels</Tab>
        <Tab>Points</Tab>
      </TabList>

      <TabPanel>
        { !this.state.dataLoaded ? 
          <LoadingSpinner/> :
          <LevelsList firebase={this.props.firebase} team={this.state.team}/>
        }
      </TabPanel>

      <TabPanel>
        { !this.state.dataLoaded ? 
          <LoadingSpinner/> :
          <PointsSettings firebase={this.props.firebase} teamId={this.state.data.team} team={this.state.team}/>
        }
      </TabPanel>


    </Tabs>);
  }

  render = () => {
    var firebase = this.props.firebase;
    var userLoaded = firebase.initialised;
    var user = this.props.firebase.user
    var team = (this.state.team) ? this.state.team : null

    if (this.state.navigateUrl)
      return (<Navigate to={this.state.navigateUrl} replace={true} />)

    return (
      <div className='App-pageframeOuter'>
        <div className='App-pageframeInner'>
          <AppHeader firebase={firebase} team={team}/>
          <AppSidebar tab="Points" firebase={firebase}/>
          <div className='App-content'>
            <div className='App-content-column'>
            {
              !userLoaded 
                ?
                <div>
                  
                </div>
                :
              user
                ? <div>
                    { this.renderTabs() }
                   </div>
                : 
                <WelcomeForm/>
            }
           </div>
          </div>

          <AppFooter tab="Levels"/>
        </div>
      </div>
    )
  }
}

export default Levels; 

