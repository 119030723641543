import React, { Component } from 'react';

import Button from './Button'
import TabBar from './TabBar';


const INITIAL_STATE = {
};

class AppSidebar extends Component {
    constructor(props) {
      super(props);
      this.state = { ...INITIAL_STATE };
   }

    render() {
      var tab = this.props.tab;
      var history = this.props.history;

      return (
        <div className="App-sidebar">
          <TabBar tab={tab} history={history}/>

       </div>
      );
    }
}

export default AppSidebar;