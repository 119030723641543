import React, { Component } from 'react';

import DataGrid from 'react-data-grid';

import moment from 'moment';

import ReportExportButtons from './ReportExportButtons'
import RowComparator from './RowComparator'

const calendarDateFormat = 'YYYY-MM-DD';

const INITIAL_STATE = { };

const defaultSortColumns = [ { columnKey: 'name', direction: 'ASC' } ];

class UsersDataGrid extends Component {
  constructor(props) {
    super(props);

    var rows = this.props.rows;
    this.defaultRowComparator = new RowComparator(defaultSortColumns);
    rows.sort(this.defaultRowComparator.compare)
    this.state = { 'rows' : rows };

    // https://us-central1-lasoo-d9fad.cloudfunctions.net/getUsersReport?team=
  }
  
  setSortColumns = (sortColumnArray) => {
    var sortColumns = sortColumnArray.concat(defaultSortColumns)
    var rowComparator = new RowComparator(sortColumns)

    this.state.rows.sort(rowComparator.compare)

    this.setState({ 'rows' : this.state.rows })

    return true;
  }

  render = () => {
    const columns = [
      { key: 'name', name: 'Name', sortable: true, width: 130, headerCellClass: 'headerCell', resizable: true },
      { key: 'score', name: 'Engagement', sortable: true, width: 140, headerCellClass: 'headerCell', cellClass: 'cellCenterAlign', resizable: true,
        formatter(props)  {
          return props.row.score + " - " + props.row.level
        }
      },
      { key: 'activeObjectives', name: 'Objs', sortable: true,  headerCellClass: 'headerCell', cellClass: 'cellRightAlign',width: 30, resizable: true },
      { key: 'totalHabits', name: 'Habits', sortable: true,  headerCellClass: 'headerCell', cellClass: 'cellRightAlign', width: 90, resizable: true },
     // { key: 'activeHabits', name: 'Habits', sortable: true,  headerCellClass: 'headerCell', cellClass: 'cellRightAlign',width: 70, resizable: true },
      { key: 'activeHabitDays', name: 'Days', sortable: true,  headerCellClass: 'headerCell', cellClass: 'cellRightAlign',width: 30, resizable: true },
      { key: 'lastStartDate', sortable: true,  name: 'Last set habit', headerCellClass: 'headerCell', cellClass: 'cellCenterAlign', width: 100, resizable: true,
        formatter(props)  {
          const value = props.row.lastStartDate
          if (!value)
            return ""
          else
            return moment(value, calendarDateFormat).format('D MMM yy')
        }
      },
      { key: 'lastTrackedDate', sortable: true,  name: 'Last checked', headerCellClass: 'headerCell', cellClass: 'cellCenterAlign', width: 100, resizable: true,
        formatter(props)  {
          const value = props.row.lastTrackedDate
          if (!value)
            return ""
          else
            return moment(value, calendarDateFormat).format('D MMM yy')
        }
    },
      { key: 'activeThisWeek', sortable: true,  name: 'Active this week', headerCellClass: 'headerCell', cellClass: 'cellCenterAlign', width: 120, resizable: true,
        formatter(props) {
          const value = props.row.activeThisWeek
          return (value) ? "Yes" : ""
        } },
    { key: 'pushToken', sortable: true,  name: 'App', headerCellClass: 'headerCell', cellClass: 'cellCenterAlign', width: 40, resizable: true,
        formatter(props) {
          const value = props.row.pushToken
          return (value) ? "Yes" : ""
        } },
    ];

    var dataGridElement = (<DataGrid className='rdg-light' style={{height: '100%', fontSize: 12 }} onSortColumnsChange={this.setSortColumns} columns={columns} rows={this.state.rows} />);

    return <div className='dataGridContainer'>{dataGridElement}<ReportExportButtons dataGrid={dataGridElement} fileName='lasoo_users'/></div>;
  }
}

export default UsersDataGrid; 

