const config = {
    apiKey: "AIzaSyD42Qqzq5nLyFXwSUPwrNR71NTvPhf979M",
    authDomain: "lasoo-d9fad.firebaseapp.com",
    databaseURL: "https://lasoo-d9fad.firebaseio.com",
    projectId: "lasoo-d9fad",
    storageBucket: "lasoo-d9fad.appspot.com",
    messagingSenderId: "264198567009",
    appId: "1:264198567009:web:d13e829b2f14eeba222620",
    measurementId: "G-JK41LWH73J"
};

export default config;

