import React, { Component } from 'react';

import Button from './Button';

const INITIAL_STATE = {
};

class EnabledTeamsSelectorButton extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onClick = () => {
    if (this.props.onChange)
      this.props.onChange()
  }

  getButtonName = () => {
    if (this.props.enabledTeamsOnly)
      return "Enabled Teams Only"
    else
      return "Enabled and Disabled Teams"
  }

  render = () => {
    return (
      <div>
        <div className="enabledTeamsSelectorButton"><Button type='secondary' size='small' onClick={this.onClick} action={this.getButtonName()}/></div>
      </div>
    )
  }
}

export default EnabledTeamsSelectorButton; 

