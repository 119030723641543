import React, { Component } from 'react';

class PopupHeader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="Popup-header">{this.props.title}</div>
    );
  }
}

export default PopupHeader;