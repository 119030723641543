import React, { Component } from 'react';

const INITIAL_STATE = {

};

/*
 * Props 
 *   type = 'primary'|'secondary'
 *   size = 'medium'|'small'
 *   onClick = function
 */
class Button extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  } 

  getClassNameProperty = () => {
    var sizeClass = (this.props.size === 'medium') ? 'btn' : 'btn-small';
    var typeClass = (this.props.type === 'primary') ? 'btn-primary' : 'btn-secondary';

    return sizeClass + ' ' + typeClass;
  }

  render = () => {
    var disabled = (this.props.disabled) ? true : false;
    return ( 
      <div className='buttonContainer'>
        <button className={this.getClassNameProperty()} onClick={this.props.onClick} disabled={disabled}>
          {this.props.action}
        </button>
      </div>
    )
  }
}

export default Button;