import React, { Component } from 'react';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import AppSidebar from './AppSidebar';
import Button from './Button'
import { Navigate } from "react-router-dom";
import WelcomeForm from './WelcomeForm';

import { getDatabase, ref, set } from "firebase/database";

import EnabledTeamsSelectorButton from './EnabledTeamsSelectorButton';
import TeamCreatePopup from './TeamCreatePopup'
import LoadingSpinner from './LoadingSpinner';

const INITIAL_STATE = {
  teams : {},
  dataLoaded: false,
  navigateUrl: null,
  showCreatePopup: false,
  creatingTeam: false,
  enabledTeamsOnly: true
};

class Switcher extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  checkDataLoaded = (force) => {
    if (this.props.firebase.user && (force || !this.state.dataLoaded) && (this.props.firebase.user.email === 'antony@lasoohabits.com' || this.props.firebase.user.email === 'g.kid@icloud.com' || this.props.firebase.user.email === 'sally@lasoohabits.com' || this.props.firebase.user.email === 'keith.abbott@adlerianconsulting.com.au' || this.props.firebase.user.email === 'david.royle@adlerianconsulting.com.au')) {
        this.props.firebase.firestore
        .collection('teams')
        .get()
        .then((snapshot) => {
          var teams = [];
          snapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              if (this.props.firebase.user.email === 'keith.abbott@adlerianconsulting.com.au' || this.props.firebase.user.email === 'david.royle@adlerianconsulting.com.au') { 
                if (doc.id === 'ozCI0HHoPHBOe1j6lRDT' || doc.id === 'x6PyI4ewOL7jJMMSi7rt')
                  teams.push({ id: doc.id, data : doc.data() });
              }
              else {
                var teamData = doc.data();
                teams.push({ id: doc.id, data : teamData });
              }

              teams.sort((t1, t2) => {
                if (t1.data.name < t2.data.name)
                  return -1;
                else if (t1.data.name > t2.data.name)
                  return 1;
                else
                  return 0;
              })
          })
          
          this.setState({dataLoaded : true, teams : teams})

        })
        .catch((error) => {
            alert('Error getting document' + error);
        });
    }
  }

  enabledTeamsSelectorChanged = () => {
    this.setState({ enabledTeamsOnly: !this.state.enabledTeamsOnly })
  }

  componentWillMount = () => {
    this.checkDataLoaded(false);
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded(false);
  }

  componentWillUnmount() {
  }

  teamClicked = (id) => {   
    const db = getDatabase();

    set(ref(db, 'users/' + this.props.firebase.user.uid + '/team'), id )
    .then(() => {
      this.setState({'navigateUrl': '/'});
    })
    .catch((error) => {
      alert("Error writing document: ", error);
    });
  }

  createTeamClicked = () => {    
    this.setState({
      showCreatePopup: true
    })
  }

  createTeamCancelled = () => {    
    this.setState({
      showCreatePopup: false
    })
  }

  createTeam = (teamName, contactName) => {
    this.setState({
      showCreatePopup: false,
      creatingTeam: true
    })

    var xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = () => { 
        if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
          this.setState({
            creatingTeam: false
          })
      
          this.checkDataLoaded(false)
        }
    }
    var url = "https://us-central1-lasoo-d9fad.cloudfunctions.net/createTeam?teamName=" + teamName + "&creatorName=" + contactName;
    xmlHttp.open("GET", url, true); // true for asynchronous 
    xmlHttp.send(null);
  
  }

  renderTeams = () => {
    if (this.state.dataLoaded && !this.state.creatingTeam) {
      var items = [];
      items.push(<div class='enabledTeamsSelectorContainer'><EnabledTeamsSelectorButton enabledTeamsOnly={this.state.enabledTeamsOnly} onChange={this.enabledTeamsSelectorChanged}/></div>)
    
      this.state.teams.forEach((team) => {

        if (this.state.enabledTeamsOnly && team.data.settings.teamDisabled)
          return;

        var teamDisabled = (team.data.settings.teamDisabled) ? " [Disabled]" : "";
        items.push(
          <div className='teamCell' onClick={this.teamClicked.bind(this, team.id)}>
            <div className='teamCellName'>{team.data.name} {teamDisabled}</div>
            <div className='teamCellNumber'>{Object.keys(team.data.members).length}</div>
            <div className='teamCellAdmin'>{team.data['creator-name']}</div>
          </div>
        );
      });
      return <div>{items}
        <div className='createNewTeamButton'><Button type='secondary' size='small' onClick={this.createTeamClicked} action='Create Team'/></div>
      </div>
    }
    else
      return <LoadingSpinner/>;
  }

  getTitle = () => {
     return " "; 
  }

  render = () => {
    if (this.state.navigateUrl) {
      var url = this.state.navigateUrl
      return (<Navigate to={url} replace={true} />)
    }

    var user = this.props.firebase.user
    var userLoaded = this.props.firebase.initialised;
    var history = this.props.history;
    
    return (
      <div className='App-pageframeOuter'>
        <div className='App-pageframeInner'>
          <AppHeader back="/" title="Switch" history={history} firebase={this.props.firebase}/>
          <AppSidebar tab="Activity" history={history} firebase={this.props.firebase}/>
          <div className='App-content'>
            <div className='App-content-column'>
            {
              !userLoaded 
              ?
              <div/>
              :
              user
              ? 
                this.renderTeams()
              : 
                <WelcomeForm history={history}/>               
            }
          { this.state.showCreatePopup ? 
             <TeamCreatePopup onCreate={this.createTeam} onCancel={this.createTeamCancelled}/>
          : <div/>}
          </div>
        </div>
        <AppFooter tab="Activity" history={history} />
      </div>
        </div >
      )

  }
}

export default Switcher;