import React, { Component } from 'react';

import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import AppSidebar from './AppSidebar';
import CircleSelectorPopup from './CircleSelectorPopup';
import WelcomeForm from './WelcomeForm'
import Button from './Button';
import LoadingSpinner from './LoadingSpinner';

import { Navigate } from "react-router-dom";
import { ref, child, get } from "firebase/database";
import { doc, updateDoc, getDoc } from "firebase/firestore";

import Select from "react-select";

const INITIAL_STATE = {
  dataLoaded: false,
  email: "",
  name: "",
  circles: [],
  manager: '',
  isContentAdmin: false,
  isTeamAdmin: false,
  displayCirclesSelector: false,
  isAddingTeamMember: false,
  navigateUrl: null
} ;

class AddTeamMember extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  componentWillUnmount() {
  }

  isAdmin = (user, team) => {
    if (user.email === 'antony@lasoohabits.com' || user.email === 'g.kid@icloud.com' || user.email === 'sally@lasoohabits.com' || user.email === 'keith.abbott@adlerianconsulting.com.au' || user.email === 'david.royle@adlerianconsulting.com.au')
      return true; 

    if (!team['team-admin'])
      return false;

    return team['team-admin'].indexOf(user.email) >= 0;
  }

  checkDataLoaded = () => {
    var data = null;

    if (this.props.firebase.user && !this.state.dataLoaded) {
      const dbRef = ref(this.props.firebase.database);

      get(child(dbRef, 'users/' + this.props.firebase.user.uid)).then((snapshot) => {
        data = snapshot.val();

        return getDoc(doc(this.props.firebase.firestore, "teams", data.team));
      })
      .then((teamSnapshot) => {
        if (teamSnapshot.exists()) {

          var team = teamSnapshot.data()

          if (this.isAdmin(this.props.firebase.user, team)) {
            this.setState({
              data: data,
              team: team,
              teamId: data.team,
              teamName: team.name,
              isAdmin: true,
              dataLoaded: true
            });
          }
          else {
            this.setState({
              isAdmin: false,
              dataLoaded: true
            });
          }
        }
      })
    }
  }


  onChangeEmail = (e) => {
    this.setState({ email: e.target.value.toLowerCase() })
  }

  onChangeName = (e) => {
    this.setState({ name: e.target.value })
  }

  onChangeManager = (option) => {
    this.setState({ manager: option.value })
  }  

  onChangeContentAdmin = (option) => {
    this.setState({ isContentAdmin: option.value })
  }

  onChangeTeamAdmin = (option) => {
    this.setState({ isTeamAdmin: option.value })
  }

  addTeamMemberClicked = () => {

    const memberEmailKey = this.state.email.toLowerCase().replaceAll(".", "_")

    if (this.state.team.member && this.state.team.member[memberEmailKey]) {
        alert("The user " + this.state.email + " is already a member of the team")
        return
    }

    this.setState({ isAddingTeamMember: true })

    var xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = () => { 
      if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
        this.setState({ isAddingTeamMember: false, navigateUrl: '/team' });
      }
    }

    var url = "https://us-central1-lasoo-d9fad.cloudfunctions.net/addUserToTeam?";

    url += "team=" + this.state.teamId + "&"
    url += "email=" + this.state.email + "&"
    url += "name=" + this.state.name + "&"
    url += "circles=" + this.state.circles.join(",") + "&"
    url += "contentadmin=" + this.state.isContentAdmin.toString() + "&"
    url += "teamadmin=" + this.state.isTeamAdmin.toString()

    if (this.state.manager && this.state.manager.length > 0) {
      url += "&manager=" + this.state.manager
    }

    xmlHttp.open("GET", url, true); // true for asynchronous 
    xmlHttp.send(null);
  }

  displayCirclesSelector = () => {
    this.setState({ displayCirclesSelector: true })
  }

  closeCirclesSelector = () => {
    this.setState({ displayCirclesSelector: false })
  }

  getManagerOptions = () => {
    var managerOptions = [ 
      { value: '', label: '' }
    ];

    if (this.state.team.member) {   
      for (const memberEmailKey of Object.keys(this.state.team.member)) {
        managerOptions.push({ 
          value: this.state.team.member[memberEmailKey].email, 
          label: this.state.team.member[memberEmailKey].name 
        })  
      }
    }

    managerOptions.sort((m1, m2) => {
      if (m1.label  < m2.label) return -1;
      if (m1.label  > m2.label) return 1;
      return 0;
    })

    return managerOptions;
  }

  circlesChanged = (circles) => {
    this.setState({ circles: circles, displayCirclesSelector: false })
  }

  getCircleNames = () => {
    var circleNames = [];

    for (const circleId of this.state.circles) {
      circleNames.push(this.state.team.circles[circleId].name)
    }

    circleNames.sort((c1, c2) => {
      if (c1 < c2) return -1;
      if (c1 > c2) return 1;
      return 0;
    });
    
    return circleNames.join(', ');
  }

  renderAddTeam = () => {
    if (!this.state.dataLoaded || this.state.isAddingTeamMember) {
      return <LoadingSpinner/>
    }
    else if (!this.state.isAdmin) {
      return <div>Only team admins have access to add team members</div>
    }
    else {
      const booleanOptions = [
        { value: false, label: 'No' },
        { value: true, label: 'Yes' },
      ];

      const managerOptions = this.getManagerOptions();
      var managerDefaultValue = managerOptions.filter(option => option.value === this.state.manager)

      const contentAdminDefaultValue = booleanOptions.filter(option => option.value === this.state.isContentAdmin)
      const teamAdminDefaultValue = booleanOptions.filter(option => option.value === this.state.isTeamAdmin)

      return <div className='settingsForm'>
        <div className='sectionTitle'>EMAIL</div>
        <input onChange={this.onChangeEmail} value={this.state.email}/>

        <div className='sectionTitle'>NAME</div>
        <input onChange={this.onChangeName} value={this.state.name}/>

        <div className='sectionTitle'>MANAGER</div>
        <Select name="manager" onChange={this.onChangeManager} defaultValue={managerDefaultValue} options={managerOptions}/>

        <div className='sectionTitle'>CIRCLES</div>
        <div className='circleSelectorBox' onClick={this.displayCirclesSelector}>{this.getCircleNames()}</div>

        <div className='sectionTitle'>CONTENT ADMIN</div>
        <Select name="contentAdmin" onChange={this.onChangeContentAdmin} defaultValue={contentAdminDefaultValue} options={booleanOptions}/>

        <div className='sectionTitle'>TEAM ADMIN</div>
        <Select name="teamAdmin" onChange={this.onChangeTeamAdmin} defaultValue={teamAdminDefaultValue} options={booleanOptions}/>

        <p><Button type='primary' size='medium' onClick={() => this.addTeamMemberClicked()} action='Add Team Member'/></p>
      </div>
    }
  }

  render = () => {
    var firebase = this.props.firebase;
    var userLoaded = firebase.initialised;
    var user = this.props.firebase.user

    if (this.state.navigateUrl) {
      var url = this.state.navigateUrl
      return (<Navigate to={url}  replace={true} />)
    }

    return (
      <div className='App-pageframeOuter'>
        <div className='App-pageframeInner'>
          <AppHeader back="/team" title={this.state.teamName} firebase={firebase} />
          <AppSidebar tab="Team" firebase={firebase}/>
          <div className='App-content'>
            <div className='App-content-column'>
            {
              !userLoaded 
                ?
                <div/>
                :
              user
                ? 
                 this.renderAddTeam()
                : 
                <WelcomeForm/>
            }
           </div>
          </div>

          { this.state.displayCirclesSelector && <CircleSelectorPopup selectedCircles={JSON.parse(JSON.stringify(this.state.circles))} team={this.state.team} onClose={this.closeCirclesSelector} onChange={this.circlesChanged}/> }

          <AppFooter tab="Team"/>
        </div>
      </div>
    )
  }
}

export default AddTeamMember; 

