import React, { Component } from 'react';

import { doc, updateDoc } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';

import DataGrid from 'react-data-grid';
import { TextEditor } from 'react-data-grid'

import Button from './Button'
import CircleCreatePopup from './CircleCreatePopup'
import RowComparator from './RowComparator'

const calendarDateFormat = 'YYYY-MM-DD';

const INITIAL_STATE = { };

const defaultSortColumns = [ { columnKey: 'name', direction: 'ASC' } ];

class CirclesList extends Component {
  constructor(props) {
    super(props);

    this.defaultRowComparator = new RowComparator(defaultSortColumns);
    this.state = { 
      showCreatePopup: false,
      'rows' : this.getRows(this.props.team) 
    };
  }

  getRow = (circleId, team) => {
    var row = { 
      id: circleId,
      name: team.circles[circleId].name,
    }
    row.description = (team.circles[circleId].description) ? team.circles[circleId].description : "";
    return row;
  }

  getRows = (team) => {
    var rows = []

    if (team.circles) {
      for (var circleId of Object.keys(team.circles)) {
        rows.push(this.getRow(circleId, team)) 
      }
    }
    rows.sort(this.defaultRowComparator.compare)
    return rows;
  }

  
  setSortColumns = (sortColumnArray) => {
    var sortColumns = sortColumnArray.concat(defaultSortColumns)
    var rowComparator = new RowComparator(sortColumns)

    this.state.rows.sort(rowComparator.compare)

    this.setState({ 'rows' : this.state.rows })

    return true;
  }

  onRowsChange = (rows) => {
    var circles = {}
    
    for (var i = 0; i < rows.length; i++) {
      circles[rows[i].id] = {
        name: rows[i].name,
        description: rows[i].description
      }
    }
    var update = {};
    update["circles"] = circles;

    updateDoc(doc(this.props.firebase.firestore, "teams", this.props.teamId), update);

    this.setState( { rows : rows })
  }

  managerChanged = (p, e) => {
    var id = p.row.id
    var managerEmail = e.target.value
    var newRows = []

    for (var i = 0; i < this.state.rows.length; i++) {
      var row = this.state.rows[i];
      if (row.id === id) {
        row.manager = this.props.team.member[managerEmail.replaceAll(".","_")].name
      }
      newRows.push(row)
    }


    this.setState({ rows: newRows })
  }

  renderDataGrid = () => {
    if (this.state.rows.length === 0)
      return <div className='noCirclesDefinedMessage'>No circles defined.</div>

    const columns = [
      { key: 'name', name: 'Name', editor: TextEditor, sortable: true, width: 150, headerCellClass: 'headerCell', resizable: true },
      { key: 'description', name: 'Description', editor: TextEditor, sortable: true, width: 150, headerCellClass: 'headerCell', resizable: true },
    ];

    return <div className='dataGridContainer'><DataGrid className='rdg-light' style={{height: '100%', fontSize: 12 }} onSortColumnsChange={this.setSortColumns} columns={columns} onRowsChange={this.onRowsChange} rows={this.state.rows} /></div>;
  }

  createCircleClicked = () => {    
    this.setState({
      showCreatePopup: true
    })
  }

  createCircleCancelled = () => {    
    this.setState({
      showCreatePopup: false
    })
  }

  createCircle = (name, description) => {
    var circle = {
      name: name,
      description: description
    }

    var circleId = "circle-" + uuidv4();
    var update = {};
    update["circles." + circleId] = circle;

    updateDoc(doc(this.props.firebase.firestore, "teams", this.props.teamId), update)
    .then(() => {
        // We really should be reloading the Team firestore document here!

      if (!this.props.team.circles)
        this.props.team.circles = {}
      
      this.props.team.circles["circles." + circleId] = circle;

      this.setState({
        showCreatePopup : false,
        'rows' : this.getRows(this.props.team)
      })
    });
  }

  renderCreateCirclePopup = () => {
    return <CircleCreatePopup onCreate={this.createCircle} onCancel={this.createCircleCancelled}/>
  }

  render = () => {
      return <div>{this.renderDataGrid()}
        <Button size='small' type='secondary' action="Create Circle" onClick={this.createCircleClicked}/>
        { this.state.showCreatePopup ? 
            this.renderCreateCirclePopup()
          : <div/>}
      </div>

  }
}

export default CirclesList; 

