import React, { Component } from 'react';

import Select from 'react-select'
import { doc, setDoc, updateDoc } from "firebase/firestore"; 

import Button from './Button';
import DataGrid, { SelectCellFormatter } from 'react-data-grid';
import RowComparator from './RowComparator'

import CircleSelectorPopup from './CircleSelectorPopup'
import moment from 'moment'

const defaultSortColumns = [ { columnKey: 'name', direction: 'ASC' } ];
const calendarDateFormat = 'YYYY-MM-DD';

class UsersList extends Component {
  constructor(props) {
    super(props);

    this.defaultRowComparator = new RowComparator(defaultSortColumns);
    this.state = { 
      'rows' : this.getRows(this.props.team)
    };
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (JSON.stringify(this.props.enabledUsersOnly) !== JSON.stringify(prevProps.enabledUsersOnly)) {
      this.setState({ 'rows' : this.getRows(this.props.team) });
    }
  }

  getRows = (team) => {
    if (!team.member)
      return [];

    var rows = []

    for (var memberEmailKey of Object.keys(team.member)) {
      if (!this.props.enabledUsersOnly || (this.props.enabledUsersOnly && !team.member[memberEmailKey].disabled))
        rows.push(this.getRow(memberEmailKey, team)) 
    }

    rows.sort(this.defaultRowComparator.compare)
    return rows;
  }

  isContentAdmin = (memberEmail, team) => {
    return team['content-admin'] && team['content-admin'].indexOf(memberEmail) >= 0;
  }

  isTeamAdmin = (memberEmail, team) => {
    return team['team-admin'] && team['team-admin'].indexOf(memberEmail) >= 0;
  }

  renderCircles = (team, circles) => {
    if (!circles)
      return
    
    var circleNames = [];
    for (var circleId of circles) {
      if (team.circles && team.circles[circleId])
        circleNames.push(team.circles[circleId].name)
    }
    circleNames.sort()
    return circleNames.join(", ");
  }

  getRow = (memberEmailKey, team) => {
    var row = {
      id : memberEmailKey
    }

    const memberId = team.member[memberEmailKey]['user-id']
    const memberEmail = team.member[memberEmailKey].email

    row.content_admin = this.isContentAdmin(memberEmail, team)
    row.team_admin = this.isTeamAdmin(memberEmail, team)
    row.email = team.member[memberEmailKey].email

    const managerEmail = team.member[memberEmailKey]['manager-email']
  
    if (managerEmail) {
      const managerEmailKey = managerEmail.replaceAll(".","_")
      if (team.member[managerEmailKey])
        row.manager = team.member[managerEmailKey].name
    }
    row.circleIds = (team.member[memberEmailKey].circles) ? team.member[memberEmailKey].circles : []
    row.circles = this.renderCircles(team, row.circleIds)
    if (team.member[memberEmailKey].disabled) {
      row.enabled = false
      row.name = team.member[memberEmailKey].name + " [Disabled]"
    }
    else {
      row.enabled = true
      row.name = team.member[memberEmailKey].name
    }

    row.joinedDate = team.member[memberEmailKey]['joined-date'] ?  team.member[memberEmailKey]['joined-date']  : null
    row.invitedDate = team.member[memberEmailKey]['invited-date'] ?  team.member[memberEmailKey]['invited-date']  : null

    return row
  }
  
  setSortColumns = (sortColumnArray) => {
    var sortColumns = sortColumnArray.concat(defaultSortColumns)
    var rowComparator = new RowComparator(sortColumns)

    this.state.rows.sort(rowComparator.compare)
    this.setState({ 'rows' : this.state.rows })

    return true;
  }

  onRowsChange = (rows) => {
    this.setState( { rows : rows })
  }

  getTeamMembersOrderedByName = (team) => {
    var teamMembers = []
    for (var memberEmailKey of Object.keys(team.member))
      teamMembers.push({ id: memberEmailKey, name: team.member[memberEmailKey].name })

    teamMembers.sort((v1, v2) => {
      if (v1.name < v2.name)
        return -1;
      else if (v1.name > v2.name) 
        return 1;
      else 
        return 0;
    })
    return teamMembers;
  }

  renderManagerDropdown = (p) => {
    var teamMembers = this.getTeamMembersOrderedByName(this.props.team)
    var options = []

    options.push(<option value={""}></option>)

    for (var teamMember of teamMembers) {
      if (teamMember.id !== p.row.id) {
        if (p.row.manager === teamMember.name)
           options.push(<option selected value={teamMember.id}>{teamMember.name}</option>)
        else 
           options.push(<option value={teamMember.id}>{teamMember.name}</option>)
      }
    }

    return <select
      className='cellDropdown'
      autoFocus
      value={this.props.team.member[p.row.id]['manager-email']}
      onChange={this.managerChanged.bind(this, p)}
    >{options}</select>
  }

  managerChanged = (p, e) => {
    var id = p.row.id
    const managerEmail = (e.target.value !== '') ? e.target.value : null;
    var newRows = []

    const managerEmailKey = (managerEmail) ? managerEmail.replaceAll(".","_") : null;

    for (var i = 0; i < this.state.rows.length; i++) {
      var row = this.state.rows[i];
      if (row.id === id) {
        row.manager = (managerEmailKey) ? this.props.team.member[managerEmailKey].name : ""
        p.onRowChange({ ...p.row, manager: e.target.value }, true)
      }
      newRows.push(row)
    }

    this.updateFirestoreField(this.props.teamId, "member." + id + ".manager-email",  managerEmail)
    this.setState({ rows: newRows })
  }

  getCirclesOrderedByName = (team) => {
    var circles = []
    if (team.circles) {
      for (var circleId of Object.keys(team.circles))
        circles.push({ id: circleId, name: team.circles[circleId].name })
    }
    circles.sort((c1, c2) => {
      if (c1.name < c2.name)
        return -1;
      else if (c1.name > c2.name) 
        return 1;
      else 
        return 0;
    })
    return circles;
  }


  renderCirclesDropdown = (p) => {
    return <CircleSelectorPopup selectedCircles={p.row.circleIds} team={this.props.team} onClose={this.circlesChanged.bind(this, p, p.row.id)} onChange={this.circlesChanged.bind(this, p, p.row.id)}/>;
  }

  circlesChanged = (p, memberEmailKey, circles) => {
    var newRows = []

    for (var i = 0; i < this.state.rows.length; i++) {
      var row = this.state.rows[i];
      if (row.id === memberEmailKey) {
        row.circleIds = circles;
        row.circles = this.renderCircles(this.props.team, circles);

        p.onRowChange({ ...row, circleIds: circles, circles: row.circles }, true)
      }
      newRows.push(row)
    }

    this.updateFirestoreField(this.props.teamId, "member." + memberEmailKey + ".circles", circles)
    this.setState({ rows: newRows })
  }

  updateFirestoreField = (teamId, fieldName, value) => {
    var update = {};
    update[fieldName] = value;

    return updateDoc(doc(this.props.firebase.firestore, "teams", teamId), update);
  }

  contentAdminFlagChanged = (memberEmailKey, flag) => {
    var contentAdminEmails = this.props.team['content-admin'] ? this.props.team['content-admin'] : [];

    var memberEmail = this.props.team.member[memberEmailKey].email

    if (flag && contentAdminEmails.indexOf(memberEmail) < 0) {
      contentAdminEmails.push(memberEmail)
    }
    else if (!flag && contentAdminEmails.indexOf(memberEmail) >= 0) {
      contentAdminEmails = contentAdminEmails.filter(function(value) { 
        return value !== memberEmail;
      })
    }

    this.updateFirestoreField(this.props.teamId, "content-admin", contentAdminEmails.sort())
  }

  teamAdminFlagChanged = (memberEmailKey, flag) => {
    var teamAdminEmails = this.props.team['team-admin'] ? this.props.team['team-admin'] : [];
    var memberEmail = this.props.team.member[memberEmailKey].email

    if (flag && teamAdminEmails.indexOf(memberEmail) < 0) {
      teamAdminEmails.push(memberEmail)
    }
    else if (!flag && teamAdminEmails.indexOf(memberEmail) >= 0) {
      teamAdminEmails = teamAdminEmails.filter(function(value) { 
        return value !== memberEmail; 
      })
    }
    this.updateFirestoreField(this.props.teamId, "team-admin", teamAdminEmails)
  }

  render = () => {
    var that = this;

    const columns = [
      { key: 'name', name: 'Name', sortable: true, width: 120, headerCellClass: 'headerCell', resizable: true },
      { key: 'manager', name: 'Manager', sortable: true,  width: 120, headerCellClass: 'headerCell', resizable: true,
        editor: (p) => {
          return this.renderManagerDropdown(p)
        },
        editorOptions: {
          editOnClick: true
        } 
      },
      { key: 'circles', name: 'Circles', sortable: true, width: 180, headerCellClass: 'headerCell', resizable: true,
        editor: (p) => {
          return this.renderCirclesDropdown(p)
        },
        editorOptions: {
          editOnClick: true
        } 
      },
      { key: 'content_admin', name: 'Content Admin', cellClass: 'cellCenterAlign', sortable: true, width: 120, headerCellClass: 'headerCell', resizable: true,  
        formatter({ row, onRowChange, isCellSelected }) {
          return (
            <SelectCellFormatter
              value={row.content_admin}
              onChange={() => {
                var newValue = !row.content_admin;
                onRowChange({ ...row, content_admin: newValue });
                that.contentAdminFlagChanged(row.id, newValue)
              }}
              isCellSelected={isCellSelected}
            />
          );
        },
      },    
      { key: 'team_admin', name: 'Team Admin', cellClass: 'cellCenterAlign', sortable: true, width: 100, headerCellClass: 'headerCell', resizable: true,
        formatter({ row, onRowChange, isCellSelected }) {
          return (
            <SelectCellFormatter
              value={row.team_admin}  
              onChange={() => {
                var newValue = !row.team_admin;

                onRowChange({ ...row, team_admin: newValue });
                that.teamAdminFlagChanged(row.id, newValue)
              }}
              isCellSelected={isCellSelected}
            />
          );
        }
      },    
      { key: 'enabled', name: 'Enabled', cellClass: 'cellCenterAlign', sortable: true, width: 80, headerCellClass: 'headerCell', resizable: true,
        formatter({ row, onRowChange, isCellSelected }) {
          return (
            <SelectCellFormatter
              value={row.enabled}  
              onChange={() => {
                var newValue = !row.enabled;

                var newName = (newValue) ? row.name.replace(" [Disabled]","") : row.name + " [Disabled]";
                onRowChange({ ...row, enabled: newValue, name: newName });

                that.updateFirestoreField(that.props.teamId, "member." + row.id + ".disabled", !newValue)

              }}
              isCellSelected={isCellSelected}
            />
          );
        },
      },
      { key: 'invitedDate', name: 'Invited', cellClass: 'cellCenterAlign', sortable: true, width: 100, headerCellClass: 'headerCell', resizable: true,
        formatter({ row, onRowChange, isCellSelected })
        {
          return (row.invitedDate) 
          ? 
            moment(row.invitedDate, calendarDateFormat).format('D MMM yy')
          : 
            row.joinedDate ?
            ''
          :
          <Button type='secondary' size='small' onClick={() => that.props.invite(row.email, row.name)} action='Invite'/>
        }
      },
      { key: 'joinedDate', name: 'Joined', cellClass: 'cellCenterAlign', sortable: true, width: 100, headerCellClass: 'headerCell', resizable: true,
        formatter({ row, onRowChange, isCellSelected }) 
        {
          return (row.joinedDate) ? 
             moment(row.joinedDate, calendarDateFormat).format('D MMM yy')
             : 
              row.invitedDate ?
              <Button type='secondary' size='small' onClick={() => that.props.invite(row.email, row.name)} action='Reinvite'/>
              :
              '';
        }
      },

    ];

    return (<div className='dataGridContainer'><DataGrid className='rdg-light' style={{height: '100%', fontSize: 12 }} onSortColumnsChange={this.setSortColumns} columns={columns} onRowsChange={this.onRowsChange} rows={this.state.rows} /></div>);
  }
}

export default UsersList; 

