import React, { Component } from 'react';

import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import AppSidebar from './AppSidebar';
import Button from './Button';
import CirclesList from './CirclesList'
import EnabledUsersSelectorButton from './EnabledUsersSelectorButton';
import LoadingSpinner from './LoadingSpinner'
import WelcomeForm from './WelcomeForm'
import UsersList from './UsersList'

import { ref, child, get } from "firebase/database";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { Navigate } from "react-router-dom";

import moment from 'moment'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const calendarDateFormat = 'YYYY-MM-DD';

const INITIAL_STATE = {
  dataLoaded: false,
  enabledUsersOnly: true,
  navigateUrl : null,
  sendingInvite: false
};

class Team extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  componentWillUnmount() {
  }

  renderEnabledUsersSelectorButton = () => {
    return <EnabledUsersSelectorButton enabledUsersOnly={this.state.enabledUsersOnly} onChange={this.enabledUsersSelectorChanged}/>
  }

  enabledUsersSelectorChanged = () => {
    this.setState({ enabledUsersOnly: !this.state.enabledUsersOnly })
  }

  isAdmin = (user, team) => {
    if (user.email === 'antony@lasoohabits.com' || user.email === 'g.kid@icloud.com' || user.email === 'sally@lasoohabits.com' || user.email === 'keith.abbott@adlerianconsulting.com.au' || user.email === 'david.royle@adlerianconsulting.com.au')
      return true; 

    if (!team['team-admin'])
      return false;

    return team['team-admin'].indexOf(user.email) >= 0;
  }

  checkDataLoaded = (force) => {
    var data = null;
    if (this.props.firebase.user && (!this.state.dataLoaded || force)) {
      const dbRef = ref(this.props.firebase.database);

      get(child(dbRef, 'users/' + this.props.firebase.user.uid)).then((snapshot) => {
        data = snapshot.val();

        return getDoc(doc(this.props.firebase.firestore, "teams", data.team));
      })
      .then((teamSnapshot) => {
        if (teamSnapshot.exists()) {
          var team = teamSnapshot.data()

          if (this.isAdmin(this.props.firebase.user, team)) {
            this.setState({
              data: data,
              team: team,
              dataLoaded: true
            });
          }
          else {
            this.setState({
              navigateUrl: "/access-denied"
            });
          }
        }
      })
    }
  }

  addTeamMemberClicked = () => {
    this.setState({ navigateUrl: "/add-team-member" })
  }

  sendImmediateNudge = (memberEmail, teamId) => {
    var xmlHttp = new XMLHttpRequest();
    
    var url = "https://us-central1-lasoo-d9fad.cloudfunctions.net/sendImmediateNudges?" +
              "email=" + memberEmail + "&" + 
              "team=" + teamId + "&" + 
              "state=Invited";

    xmlHttp.open("GET", url, true); // true for asynchronous 
    xmlHttp.send(null);
  }
   
  invite = (memberEmail, memberName) => {
    this.setState({ sendingInvite: true })

    var invite = {
      'created-time': moment().format(),
      'inviter-id': this.props.firebase.user.uid, 
      'inviter-name': this.props.firebase.user.displayName,
      'inviter-email': this.props.firebase.user.email,
      'team': this.state.data.team,
      'team-name': this.state.team.name,
      'invitee-email': memberEmail,
      'invitee-accepted': false
    };

    if (this.state.team.settings && this.state.team.settings.defaultObjective && this.state.team.settings.defaultObjective.length > 0) {
      invite['default-objective'] = this.state.team.settings.defaultObjective;
    }

    this.props.firebase.firestore.collection("invitations").add(invite)
    .then((inviteDoc) => {

      this.sendImmediateNudge(memberEmail, this.state.data.team)

      var memberEmailKey = memberEmail.replaceAll(".","_")
      var update = {}
      update["member." + memberEmailKey + ".invite-id"] = inviteDoc.id;
      update["member." + memberEmailKey + ".invited-date"] = moment().format(calendarDateFormat);

      updateDoc(doc(this.props.firebase.firestore, "teams", this.state.data.team), update)
      .then(() => {
        this.setState({ sendingInvite: false, dataLoaded: false })
        this.checkDataLoaded(true)
      })
    })
    .catch((error) => {
      alert('Error creating invite: ' + error);
    });
  }



  renderTabs = () => {
    if (this.state.navigateUrl)
      return (<Navigate to={this.state.navigateUrl}  replace={true} />)
    
    return (<Tabs>
      <TabList>
        <Tab>Users</Tab>
        <Tab>Circles</Tab>
      </TabList>

      <TabPanel>
      { !this.state.dataLoaded  || this.state.sendingInvite?  
          <LoadingSpinner/> :
          <>
            <UsersList firebase={this.props.firebase}  invite={this.invite} enabledUsersOnly={this.state.enabledUsersOnly} teamId={this.state.data.team} team={this.state.team}/>
            <Button action='Add Team Member' onClick={this.addTeamMemberClicked}/>
          </>
      }
      </TabPanel>

      <TabPanel>
      { !this.state.dataLoaded ? 
          <LoadingSpinner/> :
          <CirclesList firebase={this.props.firebase} teamId={this.state.data.team} team={this.state.team}/>
      }
      </TabPanel>
   
    </Tabs>);
  }

  render = () => {
    var firebase = this.props.firebase;
    var userLoaded = firebase.initialised;
    var user = this.props.firebase.user
    var team = this.state.team ? this.state.team : null

    if (this.state.navigateUrl)
      return (<Navigate to={this.state.navigateUrl}  replace={true} />)

    return (
      <div className='App-pageframeOuter'>
        <div className='App-pageframeInner'>
          <AppHeader firebase={firebase} team={team}/>
          <AppSidebar tab="Team" firebase={firebase}/>
          <div className='App-content'>
            <div className='App-content-column'>
            {
              !userLoaded 
                ?
                <div>
                   
                </div>
                :
              user
                ? <div>
                     { this.renderEnabledUsersSelectorButton() }
                     { this.renderTabs() }
                   </div>
                : 
                <WelcomeForm/>
            }
           </div>
          </div>

          <AppFooter tab="Team"/>
        </div>
      </div>
    )
  }
}

export default Team; 

