import React, { Component } from 'react';

import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import AppSidebar from './AppSidebar';
import WelcomeForm from './WelcomeForm'
import Button from './Button';
import LoadingSpinner from './LoadingSpinner';

import { Navigate } from "react-router-dom";

import { v4 as uuidv4 } from 'uuid'

import MessagePopup from './MessagePopup'

import { ref, child, get } from "firebase/database";
import { doc, updateDoc, getDoc } from "firebase/firestore";

import withRouter from './withRouter';

const INITIAL_STATE = {
  dataLoaded: false,

  displayValidationMessage: false,
  validationMessage: '',

  name: '',
  points: 0
};

class EditLevel extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  componentWillUnmount() {
  }

  showValidationMessage(msg) {
    this.setState({ displayValidationMessage: true, validationMessage: msg })
  }

  
  closeValidationMessage = () => {
    this.setState({ displayValidationMessage: false })
  }


  checkDataLoaded = () => {
    
    var data = null;

    if (this.props.firebase.user && !this.state.dataLoaded) {
      const dbRef = ref(this.props.firebase.database);

      get(child(dbRef, 'users/' + this.props.firebase.user.uid)).then((snapshot) => {
        data = snapshot.val();

        return getDoc(doc(this.props.firebase.firestore, "teams", data.team));
      })
      .then((teamSnapshot) => {
        if (teamSnapshot.exists()) {

          var team = teamSnapshot.data()

          var level = (this.props.params.id) ? team.levels[this.props.params.id] : null;

          if (this.isAdmin(this.props.firebase.user, team)) {
            this.setState({
              data: data,
              teamId: data.team,
              team: team,

              name: (level) ? level.name : '',
              points: (level) ? level.points : 0,

              isAdmin: true,
              dataLoaded: true,
            });
          }
          else {
            this.setState({
              isAdmin: false,
              dataLoaded: true
            });
          }
        }
      })
    }
  }

  isAdmin = (user, team) => {
    if (user.email === 'antony@lasoohabits.com' || user.email === 'g.kid@icloud.com' || user.email === 'sally@lasoohabits.com' || user.email === 'keith.abbott@adlerianconsulting.com.au' || user.email === 'david.royle@adlerianconsulting.com.au')
      return true; 

    if (!team['team-admin'])
      return false;

    return team['team-admin'].indexOf(user.email) >= 0;
  }

  isInt = (value) => {
    return value !== undefined && !isNaN(value) && parseInt(Number(value)) == value && !isNaN(parseInt(value, 10));
  }

  addOrUpdateLevel = () => {

    if (this.state.name.trim() === '') {
      this.showValidationMessage("Please enter a name for the level")
      return;
    }

    if (!this.isInt(this.state.points)) {
      this.showValidationMessage("Please enter a number for the points")
      return;
    }

    var teamRef = doc(this.props.firebase.firestore, "teams", this.state.teamId);
    const levelId = (this.props.params.id) ? this.props.params.id : "level-" + uuidv4();

    var level = {
      name: this.state.name.trim(),
      points: parseInt(this.state.points),
    }

    const updates = {}
    updates["levels." + levelId] = level
  
    updateDoc(teamRef, updates)
    .then(() => {
      this.setState({ navigateUrl: '/levels' })
    })
  }




  onChangeName = (e) => {
    this.setState({ name: e.target.value })
  }

  onChangePoints = (e) => {
    this.setState({ points: e.target.value })
  }

  renderLevel = () => {
    if (!this.state.dataLoaded) {
      return <LoadingSpinner/>
    }
    else if (!this.state.isAdmin) {
      return <div>Only team admins have access to levels</div>
    }
    else {

      const buttonLabel = (this.props.params.id) ? 'Update Level' : 'Add Level';

      return <div className='settingsForm'>
        <div className='sectionTitle'>LEVEL NAME</div>
        <input onChange={this.onChangeName} value={this.state.name}/>
        <div className='sectionTitle'>POINTS</div>
        <input onChange={this.onChangePoints} value={this.state.points}/>
        <p><Button type='primary' size='medium' onClick={() => this.addOrUpdateLevel()} action={buttonLabel}/></p>
      </div>
    }
  }

  render = () => {
    var firebase = this.props.firebase;
    var userLoaded = firebase.initialised;
    var user = this.props.firebase.user
    var title = (this.props.params.id) ? "Edit Level" : "Add Level";

    if (this.state.navigateUrl) {
      var url = this.state.navigateUrl
      return (<Navigate to={url}  replace={true} />)
    }

    return (
      <div className='App-pageframeOuter'>
        <div className='App-pageframeInner'>
          <AppHeader back="/levels" title={title} firebase={firebase} />
          <AppSidebar tab="Levels" firebase={firebase}/>
          <div className='App-content'>
            <div className='App-content-column'>
            {
              !userLoaded 
                ?
                <div>
                   
                </div>
                :
                user
                ? 
                 this.renderLevel()
                : 
                <WelcomeForm/>
            }
           </div>
          </div>

          { this.state.displayValidationMessage ? <MessagePopup title="Validation Message" message={this.state.validationMessage} onClose={this.closeValidationMessage}/> : <div/> }

          <AppFooter tab="Levels"/>
        </div>
      </div>
    )
  }
}

export default withRouter(EditLevel); 

