import React, { Component } from 'react';

import ActivityDataGrid from './ActivityDataGrid'
import ActivityByWeekChart from './ActivityByWeekChart'
import ActivityTopActiveUsersChart from './ActivityTopActiveUsersChart'
import LoadingSpinner from './LoadingSpinner'

const INITIAL_STATE = { dataLoaded : false };

class ActivityReport extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
 
  checkDataLoaded = (force) => {

    if (!this.state.dataLoaded || force) {
      var xmlHttp = new XMLHttpRequest();
      xmlHttp.onreadystatechange = () => { 
          if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
            var rows = JSON.parse(xmlHttp.responseText);
            this.setState({ dataLoaded : true, rows : rows })
          }
      }
      var url = "https://us-central1-lasoo-d9fad.cloudfunctions.net/getActivityReport?team=" + this.props.team;
 
      if (this.props.circles && this.props.circles.length > 0) {
        url += "&circles=" + this.props.circles.join(',')
      }
      if (this.props.enabledUsersOnly) {
        url += "&enabledUsersOnly=true";
      }    
       
      xmlHttp.open("GET", url, true); // true for asynchronous 
      xmlHttp.send(null);
    }
  }

  componentWillMount = () => {
    this.checkDataLoaded(false);
  }

  componentWillUnmount() {
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (JSON.stringify(this.props.circles) !== JSON.stringify(prevProps.circles) ||
        JSON.stringify(this.props.enabledUsersOnly) !== JSON.stringify(prevProps.enabledUsersOnly)) {
      this.setState({
        dataLoaded: false
      })
      this.checkDataLoaded(true);
    }
  }


  render = () => {
    if (!this.state.dataLoaded)
      return <LoadingSpinner/>     
    else {
      return <table width='100%'>
        <tr><td width='50%'><ActivityByWeekChart rows={this.state.rows}/></td>
        <td width='50%' style={{ 'vertical-align': 'top'}}><ActivityTopActiveUsersChart rows={this.state.rows}/></td></tr>
        <tr><td colspan='2'><ActivityDataGrid rows={this.state.rows}/></td></tr>
      </table>
    }
  }
}

export default ActivityReport; 

