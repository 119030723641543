import React, { Component } from 'react';

import DataGrid from 'react-data-grid';
import moment from 'moment';

import ReportExportButtons from './ReportExportButtons'
import RowComparator from './RowComparator';

const calendarDateFormat = 'YYYY-MM-DD';

const INITIAL_STATE = {
  dataLoaded : false,
  sortColumns : []
};

var defaultSortColumns = [
  { columnKey : 'name', direction: 'ASC' },
  { columnKey : 'category', direction: 'ASC' },
  { columnKey : 'habit', direction: 'ASC' },
  { columnKey : 'period', direction: 'ASC' },
  { columnKey : 'startDate', direction: 'ASC' }
]

class GoalsDataGrid extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };

    var rows = this.props.rows;

    this.defaultRowComparator = new RowComparator(defaultSortColumns);
    rows.sort(this.defaultRowComparator.compare)
    this.state = { 'rows' : rows };

    // https://us-central1-lasoo-d9fad.cloudfunctions.net/getGoalsReport?team=

  }
  
  setSortColumns = (sortColumnArray) => {
    var sortColumns = sortColumnArray.concat(defaultSortColumns)
    var rowComparator = new RowComparator(sortColumns)

    this.state.rows.sort(rowComparator.compare)

    this.setState({ 'rows' : this.state.rows })

    return true;
  }

  render = () => {
    const columns = [
      { key: 'name', name: 'Name', sortable: true, headerCellClass: 'headerCell', width: 120, resizable: true },
      { key: 'category', name: 'Category', sortable: true, headerCellClass: 'headerCell', width: 130, resizable: true },
      { key: 'habit', name: 'Habit', sortable: true, headerCellClass: 'headerCell', width: 200, resizable: true },
      { key: 'period', name: 'Period', sortable: true, headerCellClass: 'headerCell', width: 200, resizable: true,
      formatter(props) {
        const value = props.row.period;
        if (props.row.period === 'day' && props.row.daysOfWeek)
          return props.row.daysOfWeek
        else if (value === 'week')
          return 'Weekly'
        else if (value === 'month')
          return 'Monthly'
        else
          return ''
      } 
    },
      { key: 'startDate', name: 'Start', sortable: true, headerCellClass: 'headerCell', cellClass: 'cellCenterAlign', width: 100, resizable: true,
        formatter(props)  {
          const value = props.row.startDate
          if (!value)
            return ""
          else
            return moment(value, calendarDateFormat).format('D MMM yy')
        }
      },
      { key: 'endDate', name: 'End', sortable: true, headerCellClass: 'headerCell',  cellClass: 'cellCenterAlign', width: 100, resizable: true,
        formatter(props)  {
          const value = props.row.endDate
          if (!value)
            return ""
          else
            return moment(value, calendarDateFormat).format('D MMM yy')
        }   
      },
      { key: 'completion', 
        sortable: true,
        name: 'Completion', width: 90, resizable: true,
        headerCellClass: 'headerCell',
        cellClass: 'cellRightAlign',
        formatter(props) {
          const value = props.row.completion;
          return value + "%"
        }
      }

    ];

    var dataGridElement = (<DataGrid className='rdg-light' onSortColumnsChange={this.setSortColumns} style={{height: '100%', fontSize: 12 }} columns={columns} rows={this.state.rows}/>);

    return <div className='dataGridContainer'>{dataGridElement}<ReportExportButtons dataGrid={dataGridElement} fileName='lasoo_goals'/></div>;
  }
}

export default GoalsDataGrid; 

