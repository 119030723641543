import React, { Component } from 'react';

import { Bar } from 'react-chartjs-2';

import moment from 'moment';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const calendarDateFormat = 'YYYY-MM-DD';

class UsersEngagementChart extends Component {
  constructor(props) {
    super(props);

    // https://us-central1-lasoo-d9fad.cloudfunctions.net/getUsersReport?team=
  }

  render = () => {

    var scores = [ 0, 0, 0, 0, 0, 0, 0 ]
    var total = 0
    var count = 0

    var activeThisWeek = 0;
    var lastWeek = moment().subtract(7, 'days').format(calendarDateFormat)

    for (var row of this.props.rows) {
      scores[row.score] = scores[row.score] + 1
      total += row.score
      count += 1
      if (row.lastTrackedDate && row.lastTrackedDate >= lastWeek)
        activeThisWeek += 1;
    }

    var average = Math.round(((count) ? total / count : 0) * 100) / 100
    var activeThisWeekPercentage = Math.round((count) ? 100.0 * activeThisWeek / count : 0)

    const options = {
   //   indexAxis: 'y',
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
      //      callback: function(value) {if (value % 1 === 0) {return value;}},
            ticks: {
              stepSize: 1,
              precision: 0

           }
          }
        }
      },
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'right',
        },
        title: {
          display: true,
          text: 'ENGAGEMENT (AVG SCORE - ' + average + ', ACTIVE THIS WEEK - ' + activeThisWeekPercentage + '%)',
        },
      },
    };



    const dataHorBar = {
      labels: [ "Added", "Invited", "Activated", "Set Habit", "Tracked Habit", "Maintained Habit", "Maintained Habits" ],
      datasets: [
        {
          label: 'Goal count',
          backgroundColor: 'rgba(234, 41, 82, 0.5)',
          borderColor: '#ea2952',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: scores
        }
      ]
    };

    return <div className='activityByWeekChart'><Bar options={options} data={dataHorBar} /></div>
  }
}

export default UsersEngagementChart; 

