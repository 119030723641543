import React, { Component } from 'react';

import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import AppSidebar from './AppSidebar';
import WelcomeForm from './WelcomeForm'
import Button from './Button';
import LoadingSpinner from './LoadingSpinner';

import Toggle from 'react-toggle'
import 'react-toggle/style.css';

import MDEditor from '@uiw/react-md-editor';

import { Navigate } from "react-router-dom";

import { v4 as uuidv4 } from 'uuid'

import MessagePopup from './MessagePopup'

import { ref, child, get } from "firebase/database";
import { doc, updateDoc, getDoc } from "firebase/firestore";

import withRouter from './withRouter';

import Select from "react-select";
import { toHaveAccessibleDescription } from '@testing-library/jest-dom/dist/matchers';

const INITIAL_STATE = {
  dataLoaded: false,

  displayValidationMessage: false,
  validationMessage: '',

  displayInfoPopup: false,

  name: '',
  state: 'Added',
  delay: 0,
  circles: '',
  emailSubject: '',
  emailTemplate: '',
  notificationTemplate: '',

  enabled: true

};

class EditNudge extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  componentWillUnmount() {
  }

  showValidationMessage(msg) {
    this.setState({ displayValidationMessage: true, validationMessage: msg })
  }

  
  closeValidationMessage = () => {
    this.setState({ displayValidationMessage: false })
  }

  showInfoPopup = () => {
    this.setState({ displayInfoPopup: true })
  }

  
  closeInfoPopup = () => {
    this.setState({ displayInfoPopup: false })
  }

  checkDataLoaded = () => {
    
    var data = null;

    if (this.props.firebase.user && !this.state.dataLoaded) {
      const dbRef = ref(this.props.firebase.database);

      get(child(dbRef, 'users/' + this.props.firebase.user.uid)).then((snapshot) => {
        data = snapshot.val();

        return getDoc(doc(this.props.firebase.firestore, "teams", data.team));
      })
      .then((teamSnapshot) => {
        if (teamSnapshot.exists()) {

          var team = teamSnapshot.data()

          var nudge = (this.props.params.id) ? team.nudges[this.props.params.id] : null;

          if (this.isAdmin(this.props.firebase.user, team)) {
            this.setState({
              data: data,
              teamId: data.team,
              team: team,

              name: (nudge) ? nudge.name : '',
              state: (nudge) ? nudge.state : 'Added',

              delay: (nudge) ? nudge.delay : 0,
              allowStateChanges: (nudge && nudge.allowStateChanges) ? true : false,

              circles: (nudge) ? nudge.circles.split(",") : [],
              emailSubject: (nudge) ? nudge['email-subject'] : '',
              emailTemplate: (nudge) ? nudge['email-template'] : '',
              notificationTemplate: (nudge) ? nudge['notification-template']: '',
              enabled: (nudge) ? nudge.enabled : true,

              isAdmin: true,
              dataLoaded: true,
            });
          }
          else {
            this.setState({
              isAdmin: false,
              dataLoaded: true
            });
          }
        }
      })
    }
  }

  isAdmin = (user, team) => {
    if (user.email === 'antony@lasoohabits.com' || user.email === 'g.kid@icloud.com' || user.email === 'sally@lasoohabits.com' || user.email === 'keith.abbott@adlerianconsulting.com.au' || user.email === 'david.royle@adlerianconsulting.com.au')
      return true; 

    if (!team['team-admin'])
      return false;

    return team['team-admin'].indexOf(user.email) >= 0;
  }

  isInt = (value) => {
    return value !== undefined && !isNaN(value) && parseInt(Number(value)) == value && !isNaN(parseInt(value, 10));
  }

  addOrUpdateNudge = () => {

    if (this.state.name.trim() === '') {
      this.showValidationMessage("Please enter a name for the nudge")
      return;
    }

    if (!this.isInt(this.state.delay)) {
      this.showValidationMessage("Please enter a number for the delay")
      return;
    }

    if (this.state.emailSubject.trim() === '') {
      this.showValidationMessage("Please enter a subject line for the email")
      return;
    }

    if (this.state.emailTemplate.trim() === '') {
      this.showValidationMessage("Please enter a template for the email")
      return;
    }

    /*
    if (this.state.state !== 'Added' && this.state.state !== 'Invited' && this.state.notificationTemplate.trim() === '') {
      this.showValidationMessage("Please enter a template for the notification")
      return;
    } */

    if (this.state.state === 'Maintained Habit' && parseInt(this.state.delay) === 0) {
      this.showValidationMessage("Immediate nudges not supported for Maintained Habit")
      return;
    }

    var teamRef = doc(this.props.firebase.firestore, "teams", this.state.teamId);
    const nudgeId = (this.props.params.id) ? this.props.params.id : "nudge-" + uuidv4();

    var nudge = {
      name: this.state.name.trim(),
      state: this.state.state,
      delay: parseInt(this.state.delay),
      allowStateChanges: this.state.allowStateChanges,
      circles: this.state.circles.join(","),
      'email-subject': this.state.emailSubject.trim(),
      'email-template': this.state.emailTemplate.trim(),
      'notification-template': this.state.notificationTemplate.trim(),
      enabled: this.state.enabled
    }

    const updates = {}
    updates["nudges." + nudgeId] = nudge
  
    updateDoc(teamRef, updates)
    .then(() => {
      this.setState({ navigateUrl: '/nudges' })
    })
  }

  renderCirclesSelect = () => {
    var options = []
    var defaultValues = [];

    Object.keys(this.state.team.circles).forEach(circleId => {
      var option = { value: circleId, label: this.state.team.circles[circleId].name }
        
      options.push(option)
      if (this.state.circles.indexOf(circleId) >= 0)
        defaultValues.push(option)
    })

    options.sort((o1, o2) => {
      if (o1.label < o2.label) return -1;
      if (o1.label > o2.label) return 1;
      return 0;
    });

    return <div>
      <Select name="circles" isMulti defaultValue={defaultValues} options={options} onChange={this.onCirclesChanged}/>
    </div>
  }

  onCirclesChanged = (options) => {
    this.setState({ circles: options.map((item) => item.value) })
  }

  onChangeAllowStateChanges = (option) => {
    this.setState({ allowStateChanges: option.value })
  }

  onChangeName = (e) => {
    this.setState({ name: e.target.value })
  }

  onChangeState = (option) => {
    this.setState({ state: option.value })
  }

  onChangeDelay = (e) => {
    this.setState({ delay: e.target.value })
  }

  onChangeEmailSubject = (e) => {
    this.setState({ emailSubject: e.target.value })
  }

  onChangeEmailTemplate = (emailTemplate) => {
    this.setState({ emailTemplate: emailTemplate })
  }

  onChangeNotificationTemplate = (e) => {
    this.setState({ notificationTemplate: e.target.value })
  }

  onChangeEnabled = (event) => {
    this.setState({ enabled: !this.state.enabled})
  }

  renderNudge = () => {
    if (!this.state.dataLoaded) {
      return <LoadingSpinner/>
    }
    else if (!this.state.isAdmin) {
      return <div>Only team admins have access to settings</div>
    }
    else {
      var stateOptions = [ 
        { value: 'Added', label: 'Added' },
        { value: 'Invited', label: 'Invited' },
        { value: 'Activated', label: 'Activated' },
        { value: 'Set Habit', label: 'Set Habit' },
        { value: 'Tracked Habit', label: 'Tracked Habit' },
        { value: 'Maintained Habit', label: 'Maintained Habit' }
      ];

      const stateDefaultValue = stateOptions.find((option) => { return option.value === this.state.state });

      var allowStateChangesOptions = [ 
        { value: false, label: 'No' },
        { value: true, label: 'Yes' }
      ];

      const allowStateChangesDefaultValue = allowStateChangesOptions.find((option) => { return option.value === this.state.allowStateChanges });

      const buttonLabel = (this.props.params.id) ? 'Update Nudge' : 'Add Nudge';

      return <div className='settingsForm'>
        <div className='sectionTitle'>NUDGE NAME</div>
        <input onChange={this.onChangeName} value={this.state.name}/>
        <div className='sectionTitle'>STATE</div>
        <Select name="state" onChange={this.onChangeState} defaultValue={stateDefaultValue} options={stateOptions}/> 
        <div className='sectionTitle'>DELAY (DAYS)</div>
        <input onChange={this.onChangeDelay} value={this.state.delay}/>

        { this.state.delay > 0 && <div>
            <div className='sectionTitle'>ALLOW STATE CHANGES (DURING DELAY)</div>
            <Select name="allowStateChanges" onChange={this.onChangeAllowStateChanges} defaultValue={allowStateChangesDefaultValue} options={allowStateChangesOptions}/> 
          </div> 
        } 


        <div className='sectionTitle'>CIRCLES</div>
        { this.renderCirclesSelect() }
        <div className='sectionTitle'>EMAIL SUBJECT
          <img className='editNudgeInfoTriangle' src="/icons/info-triangle.png" onClick={this.showInfoPopup}/>

        </div>
        <input onChange={this.onChangeEmailSubject} value={this.state.emailSubject}/>
        <div className='sectionTitle'>
          EMAIL TEMPLATE
          <img className='editNudgeInfoTriangle' src="/icons/info-triangle.png" onClick={this.showInfoPopup}/>
        </div>
        <MDEditor
          preview='edit'
          value={this.state.emailTemplate}
          onChange={this.onChangeEmailTemplate}
        />
        { false && this.state.state !== 'Added' && this.state.state !== 'Invited' && 
         <>
          <div className='sectionTitle'>NOTIFICATION TEMPLATE
            <img className='editNudgeInfoTriangle' src="/icons/info-triangle.png" onClick={this.showInfoPopup}/>
          </div>
          <input onChange={this.onChangeNotificationTemplate} value={this.state.notificationTemplate}/>
        </>
        }
        <div className='sectionTitle'>ENABLED</div>
        <div><Toggle defaultChecked={this.state.enabled} onChange={this.onChangeEnabled} />
        </div>
        <p><Button type='primary' size='medium' onClick={() => this.addOrUpdateNudge()} action={buttonLabel}/></p>
      </div>
    }
  }

  render = () => {
    var firebase = this.props.firebase;
    var userLoaded = firebase.initialised;
    var user = this.props.firebase.user
    var title = (this.props.params.id) ? "Edit Nudge" : "Add Nudge";

    if (this.state.navigateUrl) {
      var url = this.state.navigateUrl
      return (<Navigate to={url}  replace={true} />)
    }

    return (
      <div className='App-pageframeOuter'>
        <div className='App-pageframeInner'>
          <AppHeader back="/nudges" title={title} firebase={firebase} />
          <AppSidebar tab="Nudges" firebase={firebase}/>
          <div className='App-content'>
            <div className='App-content-column'>
            {
              !userLoaded 
                ?
                <div>
                   
                </div>
                :
                user
                ? 
                 this.renderNudge()
                : 
                <WelcomeForm/>
            }
           </div>
          </div>

          { this.state.displayValidationMessage ? <MessagePopup title="Validation Message" message={this.state.validationMessage} onClose={this.closeValidationMessage}/> : <div/> }

          { this.state.displayInfoPopup ? <MessagePopup title="Nudge Variables" message="Supported variables: {firstName} {email} {fullName} " onClose={this.closeInfoPopup}/> : <div/> }

          <AppFooter tab="Nudges"/>
        </div>
      </div>
    )
  }
}

export default withRouter(EditNudge); 

