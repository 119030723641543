import React, { Component } from 'react';

import DataGrid from 'react-data-grid';
import moment from 'moment';

import ReportExportButtons from './ReportExportButtons';
import RowComparator from './RowComparator';

const calendarDateFormat = 'YYYY-MM-DD';
const INITIAL_STATE = {};

const defaultSortColumns = [ 
  { columnKey: 'date', direction: 'DESC' },
  { columnKey: 'index', direction: 'DESC' },
];

class NudgesLog extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };

    var rows = this.props.rows;
    this.defaultRowComparator = new RowComparator(defaultSortColumns);
    if (rows)
      rows.sort(this.defaultRowComparator.compare)

    this.state = { 'rows' : this.getRows(this.props.team) };
  }

  getRows = (team) => {
    if (!team['nudges-log'])
      return [];

    var rows = []

    for (var date of Object.keys(team['nudges-log'])) {
      for (var index of Object.keys(team['nudges-log'][date])) 
        rows.push(this.getRow(date, index, team)) 
    }

    rows.sort(this.defaultRowComparator.compare)
    return rows;
  }

  isContentAdmin = (memberEmail, team) => {
    return team['content-admin'] && team['content-admin'].indexOf(memberEmail) >= 0;
  }

  isTeamAdmin = (memberEmail, team) => {
    return team['team-admin'] && team['team-admin'].indexOf(memberEmail) >= 0;
  }

  getRow = (date, index, team) => {
    var row = {
      date : date,
      index: index
    }

    const nudgeLog = team['nudges-log'][date][index]

    row.nudgeName = nudgeLog['nudge-name']

    if (nudgeLog['delay'] === 0)
      row.delay = "Immediate" 
    else if (nudgeLog['delay'] === 1)
      row.delay = "1 day"
    else
      row.delay = nudgeLog['delay'] + " days"

    row.recipientName = nudgeLog['recipient-name'] 
    row.recipientStatus = nudgeLog['recipient-status'] 
    row.sentNotification = nudgeLog['sent-notification'] ? "Yes" : "No"

    return row
  }

  setSortColumns = (sortColumnArray) => {
    var sortColumns = sortColumnArray.concat(defaultSortColumns)
    var rowComparator = new RowComparator(sortColumns)

    this.state.rows.sort(rowComparator.compare)
    this.setState({ 'rows' : this.state.rows })

    return true;
  }

  render = () => {

    const columns = [
      { key: 'date', sortable: true, headerCellClass: 'headerCell', 
        formatter(props)  {
          const value = props.row.date
          if (!value)
            return ""
          else
            return moment(value, calendarDateFormat).format('D MMM yy')
        },
        cellClass: 'cellCenterAlign', name: 'Date', cellClass: 'cellCenterAlign', width: 100, resizable: true },
      { key: 'recipientName', sortable: true, headerCellClass: 'headerCell', cellClass: 'cellCenterAlign', name: 'User', width: 150, resizable: true },
      { key: 'nudgeName', sortable: true, headerCellClass: 'headerCell', name: 'Nudge', width: 300, resizable: true },
      { key: 'recipientStatus', sortable: true, headerCellClass: 'headerCell', cellClass: 'cellCenterAlign', name: 'Status', width: 60, resizable: true },
      { key: 'delay', sortable: true, headerCellClass: 'headerCell', cellClass: 'cellCenterAlign', name: 'Delay', width: 100, resizable: true },
      { key: 'sentNotification', sortable: true, headerCellClass: 'headerCell', name: 'Notification', cellClass: 'cellCenterAlign', width: 100, resizable: true },
    ];

    var dataGridElement = (<DataGrid className='rdg-light' style={{height: '100%', fontSize: 12 }} onSortColumnsChange={this.setSortColumns} columns={columns} rows={this.state.rows} />);

    return <div className='dataGridContainer'>{dataGridElement}<ReportExportButtons dataGrid={dataGridElement} fileName='lasoo_nudge_log'/></div>;
  }
}

export default NudgesLog; 

