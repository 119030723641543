import app from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import config from './firebaseConfig';

import { getDatabase } from "firebase/database";

class Firebase {
  constructor(onAuthStateChangedCallback) {
    app.initializeApp(config);

    this.auth = app.auth();
    this.onAuthStateChangedCallback = onAuthStateChangedCallback;
    this.initialised = false;

    this.emailAuthProvider = app.auth.EmailAuthProvider;
    this.googleProvider = new app.auth.GoogleAuthProvider();

    this.firestore = app.firestore();
    this.database = getDatabase();

    this.auth.onAuthStateChanged((user) => {
      /* This will be invoked if there is a user logged in or not */
      this.user = user;
      this.initialised = true;
      this.onAuthStateChangedCallback(this.user)
    });
  }

  createUserSuccess = (result) =>
    this.user = result.user;

  signInSuccess = (result) => {
    this.user = result.user;
  }

  signOutSuccess = () => {
    this.user = null;
  }

  doCreateUserWithEmailAndPassword = (name, email, password) => 
    this.auth.createUserWithEmailAndPassword(email, password).then((userCredential) => {
      // [END createwithemail]
      // callSomeFunction(); Optional
      // var user = firebase.auth().currentUser;

      userCredential.user.updateProfile({
          displayName: name
      });        

      this.createUserSuccess(userCredential.user);
    });

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password).then(this.signInSuccess)

  doSignInWithGoogle = () => {
    if ('standalone' in window.navigator && window.navigator.standalone) {
       this.auth.signInWithRedirect(this.googleProvider)
       return this.auth.getRedirectResult().then(this.signInSuccess)
    }
    else
      return this.auth.signInWithPopup(this.googleProvider).then(this.signInSuccess)
  }

  doSignOut = () =>
    this.auth.signOut().then(this.signOutSuccess);

  doPasswordReset = email =>
    this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);
}
export default Firebase;