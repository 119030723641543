import React, { Component } from 'react';

import { Bar } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


class UsersMostHabitsChart extends Component {
  constructor(props) {
    super(props);

    // https://us-central1-lasoo-d9fad.cloudfunctions.net/getUsersReport?team=
  }

  calclulateTopActiveUsers = (rows) => {
    var nameAndTotalHabits = [];
    for (var row of rows) {
      nameAndTotalHabits.push({ name: row.name, totalHabits: row.totalHabits })
    }

    nameAndTotalHabits.sort((v1, v2) => {
      if (v1.totalHabits < v2.totalHabits)
        return 1;
      else if (v1.totalHabits > v2.totalHabits)
        return -1;
      else if (v1.name < v2.name) 
        return -1;
      else 
        return 1;
    })

    var names = [];
    var totalHabits = [];
    var count = 0;

    for (var nameAndTotalHabit of nameAndTotalHabits) {
      if (count < 9) {
        names.push(nameAndTotalHabit.name)
        totalHabits.push(nameAndTotalHabit.totalHabits)
        count += 1
      }
    }

    return {
      names: names,
      totalHabits: totalHabits
    }
  }


  render = () => {
    var topActiveUsers = this.calclulateTopActiveUsers(this.props.rows);

    const options = {
      indexAxis: 'y',
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'right',
        },
        title: {
          display: true,
          text: 'USER TOTAL HABITS',
        },
      },
    };

    const dataHorBar = {
      labels: topActiveUsers.names,
      datasets: [
        {
          label: 'Active users',
          backgroundColor: 'rgba(234, 41, 82, 0.5)',
          borderColor: '#ea2952',

          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: topActiveUsers.totalHabits
        }
      ]
    };

    return <Bar options={options} data={dataHorBar} />
  }
}

export default UsersMostHabitsChart; 

