import React, { Component } from 'react';

import WelcomeForm from './WelcomeForm'
import Button from './Button';
import LoadingSpinner from './LoadingSpinner';

import { ref, child, get } from "firebase/database";
import { doc, updateDoc, getDoc } from "firebase/firestore";

import Select from "react-select";

const INITIAL_STATE = {
  dataLoaded: false,
  displayPoints: false
};

class PointsSettings extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  componentWillUnmount() {
  }

  isAdmin = (user, team) => {
    if (user.email === 'antony@lasoohabits.com' || user.email === 'g.kid@icloud.com' || user.email === 'sally@lasoohabits.com' || user.email === 'keith.abbott@adlerianconsulting.com.au' || user.email === 'david.royle@adlerianconsulting.com.au')
      return true; 

    if (!team['team-admin'])
      return false;

    return team['team-admin'].indexOf(user.email) >= 0;
  }

  checkDataLoaded = () => {
    var data = null;

    if (this.props.firebase.user && !this.state.dataLoaded) {
      const dbRef = ref(this.props.firebase.database);

      get(child(dbRef, 'users/' + this.props.firebase.user.uid)).then((snapshot) => {
        data = snapshot.val();

        return getDoc(doc(this.props.firebase.firestore, "teams", data.team));
      })
      .then((teamSnapshot) => {
        if (teamSnapshot.exists()) {

          var team = teamSnapshot.data()

          if (this.isAdmin(this.props.firebase.user, team)) {
            this.setState({
              data: data,
              teamId: data.team,
              team: team,
              addHabitPoints: (team.points && team.points.addHabit) ? team.points.addHabit : 1,
              checkinPoints: (team.points && team.points.checkin) ? team.points.checkin : 1,
              fiveStreakPoints: (team.points && team.points.fiveStreak) ? team.points.fiveStreak : 5,
              tenStreakPoints: (team.points && team.points.tenStreak) ? team.points.tenStreak : 10,
              twentyStreakPoints: (team.points && team.points.twentyStreak) ? team.points.twentyStreak : 20,
              fiftyStreakPoints: (team.points && team.points.fiftyStreak) ? team.points.fiftyStreak : 50,
              commentPoints: (team.points && team.points.comment) ? team.points.comment : 2,
              likePoints: (team.points && team.points.like) ? team.points.like : 1,

              isAdmin: true,
              dataLoaded: true,
            });
          }
          else {
            this.setState({
              isAdmin: false,
              dataLoaded: true
            });
          }
        }
      })
    }
  }

  onChangeShowPoints = (option) => {
    this.setState({ showPoints: option.value })
  }  

  onChangeAddHabitPoints = (e) => {
    this.setState({ addHabitPoints: e.target.value })
  }

  onChangeCheckinPoints = (e) => {
    this.setState({ checkinPoints: e.target.value })
  }

  onChangeCommentPoints = (e) => {
    this.setState({ commentPoints: e.target.value })
  }

  onChangeLikePoints = (e) => {
    this.setState({ likePoints: e.target.value })
  }




  onChangeFiveStreakPoints = (e) => {
    this.setState({ fiveStreakPoints: e.target.value })
  }

  onChangeTenStreakPoints = (e) => {
    this.setState({ tenStreakPoints: e.target.value })
  }

  onChangeTwentyStreakPoints = (e) => {
    this.setState({ twentyStreakPoints: e.target.value })
  }

  onChangeFiftyStreakPoints = (e) => {
    this.setState({ fiftyStreakPoints: e.target.value })
  }

  isInt = (value) => {
    return value !== undefined && !isNaN(value) && parseInt(Number(value)) == value && !isNaN(parseInt(value, 10));
  }


  updateSettings = () => {
    var teamRef = doc(this.props.firebase.firestore, "teams", this.state.teamId);

    if (!this.isInt(this.state.addHabitPoints) || !this.isInt(this.state.checkinPoints) || !this.isInt(this.state.fiveStreakPoints) || !this.isInt(this.state.tenStreakPoints) || !this.isInt(this.state.twentyStreakPoints) || !this.isInt(this.state.fiftyStreakPoints) || !this.isInt(this.state.commentPoints) || !this.isInt(this.state.likePoints)) {
      alert("Please enter numbers for each of the points settings")
      return;
    }

    updateDoc(teamRef, {
      "points.addHabit": this.state.addHabitPoints,
      "points.checkin": this.state.checkinPoints,
      "points.fiveStreak": this.state.fiveStreakPoints,
      "points.tenStreak": this.state.tenStreakPoints,
      "points.twentyStreak": this.state.twentyStreakPoints,
      "points.fiftyStreak": this.state.fiftyStreakPoints,
      "points.comment": this.state.commentPoints,
      "points.like": this.state.likePoints,
    })
    .then(() => {
      alert("Points Updated Successfully")
    })
  }

  renderSettings = () => {
    if (!this.state.dataLoaded) {
      return <LoadingSpinner/>
    }
    else if (!this.state.isAdmin) {
      return <div>Only team admins have access to settings</div>
    }
    else {    
      return <div className='settingsForm'>

        <div className='sectionTitle'>ADD HABIT POINTS</div>
        <input onChange={this.onChangeAddHabitPoints} value={this.state.addHabitPoints}/>

        <div className='sectionTitle'>CHECK-IN POINTS</div>
        <input onChange={this.onChangeCheckinPoints} value={this.state.checkinPoints}/>

        <div className='sectionTitle'>CHECK-IN POINTS (&gt; 5 STREAK)</div>
        <input onChange={this.onChangeFiveStreakPoints} value={this.state.fiveStreakPoints}/>

        <div className='sectionTitle'>CHECK-IN POINTS (&gt; 10 STREAK)</div>
        <input onChange={this.onChangeTenStreakPoints} value={this.state.tenStreakPoints}/>

        <div className='sectionTitle'>CHECK-IN POINTS (&gt; 20 STREAK)</div>
        <input onChange={this.onChangeTwentyStreakPoints} value={this.state.twentyStreakPoints}/>

        <div className='sectionTitle'>CHECK-IN POINTS (&gt; 50 STREAK)</div>
        <input onChange={this.onChangeFiftyStreakPoints} value={this.state.fiftyStreakPoints}/>

        <div className='sectionTitle'>COMMENT POINTS</div>
        <input onChange={this.onChangeCommentPoints} value={this.state.commentPoints}/>

        <div className='sectionTitle'>LIKE POINTS</div>
        <input onChange={this.onChangeLikePoints} value={this.state.likePoints}/>

        <p><Button type='primary' size='medium' onClick={() => this.updateSettings()} action='Update Points'/></p>
      </div>
    }
  }

  render = () => {
    var firebase = this.props.firebase;
    var userLoaded = firebase.initialised;
    var user = this.props.firebase.user

    return (
      <div>
            {
              !userLoaded 
                ?
                <div/>
                :
              user
                ? 
                 this.renderSettings()
                : 
                <WelcomeForm/>
            }
      </div>
    )
  }
}

export default PointsSettings; 

