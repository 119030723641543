import React, { Component } from 'react';

import Button from './Button'

import { exportToCsv, exportToPdf, exportToXlsx } from './ExportUtils';

/*
 * Props 
 *   dataGrid = DataGrid element
 *   fileName = file name (not including extension)
 */
class ReportExportButtons extends Component {
  constructor(props) {
    super(props);
  } 

  render = () => {
    return <table className='reportExportButtons'><tr>
        <td><Button size='small' type='secondary' onClick={ () => exportToCsv(this.props.dataGrid, this.props.fileName + ".csv") } action='Export to CSV'/></td>
        <td><Button size='small' type='secondary' onClick={ () => exportToPdf(this.props.dataGrid, this.props.fileName + ".pdf") } action='Export to PDF'/></td>
        <td><Button size='small' type='secondary' onClick={ () => exportToXlsx(this.props.dataGrid, this.props.fileName + ".xlsx") } action='Export to XLSX'/></td>
      </tr></table>
  }
}

export default ReportExportButtons;