import React, { Component } from 'react';

import { Navigate } from "react-router-dom";
import { ref, child, get } from "firebase/database";
import { doc, getDoc } from "firebase/firestore";

import Avatar from './Avatar';

const INITIAL_STATE = {
  navigateUrl: null
};

class AppHeader extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE
  }

  backClicked = () => {
    this.setState({ navigateUrl : this.props.back })
  }

  avatarClicked = () => {
    this.setState({ navigateUrl : "/profile" })
  }

  logoClicked = () => {
    this.setState({ navigateUrl : "/switch" })
  }

  isSuperAdmin = () => {
    return (this.props.firebase && this.props.firebase.user && (this.props.firebase.user.email === 'antony@lasoohabits.com' || this.props.firebase.user.email === 'g.kid@icloud.com' || this.props.firebase.user.email === 'sally@lasoohabits.com' || this.props.firebase.user.email === 'keith.abbott@adlerianconsulting.com.au' || this.props.firebase.user.email === 'david.royle@adlerianconsulting.com.au')) 
  }

  render() {
    if (this.state.navigateUrl) {
      var url = this.state.navigateUrl
      return (<Navigate to={url}  replace={true} />)
    }

    var title = this.props.title;
    var items = []

    if (this.isSuperAdmin() && !title) 
      title = (this.props.team) ? this.props.team.name : " "

    items.push(
      <div className='headerTitle'>
        <div className='navTitle'>{title}</div>
      </div>
    );

    if (this.props.back)
      items.push(<div className="App-header-back" onClick={this.backClicked} />);
    else if (this.isSuperAdmin()) 
      items.push(<img className='App-header-logo' onClick={this.logoClicked} src='/lasoo_icon.png'/>);

    if (!title)
      items.push(<p className='headerIconContainer'><img className='headerIcon' src='/lasoo_admin_small.png' /></p>);

      /*
    if (this.props.firebase && this.props.firebase.user && !this.props.back) 
      items.push(<img className='App-header-logo' onClick={this.logoClicked} src='/lasoo_icon.png'/>);
*/

    if (this.props.firebase && this.props.firebase.user) 
      items.push(<div className='App-header-avatar' onClick={this.avatarClicked}><Avatar name={this.props.firebase.user.displayName} image={this.props.firebase.user.photoURL} firebase={this.props.firebase.user}/></div>);

    return (
      <div className="App-header">{items}</div>
    );
  }
}

export default AppHeader;