import React, { Component } from 'react';

import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import AppSidebar from './AppSidebar';
import Button from './Button';
import WelcomeForm from './WelcomeForm'

const INITIAL_STATE = {
  dataLoaded: false
};

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  signout = () => this.props.firebase.doSignOut().then(() => this.forceUpdate())

  render = () => {
    var firebase = this.props.firebase;
    var userLoaded = firebase.initialised;
    var user = this.props.firebase.user

    return (
      <div className='App-pageframeOuter'>
        <div className='App-pageframeInner'>
          <AppHeader firebase={firebase} />
          <AppSidebar tab="Dashboard" firebase={firebase}/>
          <div className='App-content'>
            <div className='App-content-column-narrow'>
            {
              !userLoaded 
                ?
                <div>
                   
                </div>
                :
              user
                ? <div>
                    <p><Button type='primary' size='medium' onClick={() => this.signout()} action='Logout'/></p>
                  </div>
                : 
                <WelcomeForm/>
            }
           </div>
          </div>

          <AppFooter tab="Dashboard"/>
        </div>
      </div>
    )
  }
}

export default Profile; 

