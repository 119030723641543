import React, { Component } from 'react';

import DataGrid from 'react-data-grid';
import moment from 'moment';

import ReportExportButtons from './ReportExportButtons';
import RowComparator from './RowComparator';

const calendarDateFormat = 'YYYY-MM-DD';
const INITIAL_STATE = {};

const defaultSortColumns = [ 
  { columnKey: 'date', direction: 'DESC' },
  { columnKey: 'index', direction: 'DESC' },
];

class ActivityDataGrid extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };

    var rows = this.props.rows;
    this.defaultRowComparator = new RowComparator(defaultSortColumns);
    if (rows)
      rows.sort(this.defaultRowComparator.compare)
    this.state = { 'rows' : rows };
  }

  setSortColumns = (sortColumnArray) => {
    var sortColumns = sortColumnArray.concat(defaultSortColumns)
    var rowComparator = new RowComparator(sortColumns)

    this.state.rows.sort(rowComparator.compare)
    this.setState({ 'rows' : this.state.rows })

    return true;
  }

  render = () => {

    const columns = [
      { key: 'date', sortable: true, headerCellClass: 'headerCell', 
        formatter(props)  {
          const value = props.row.date
          if (!value)
            return ""
          else
            return moment(value, calendarDateFormat).format('D MMM yy')
        },
        cellClass: 'cellCenterAlign', name: 'Date', width: 100, resizable: true },
      { key: 'name', sortable: true, headerCellClass: 'headerCell', name: 'Name', width: 150, resizable: true },
      { key: 'objective', sortable: true, headerCellClass: 'headerCell', name: 'Objective', width: 150, resizable: true },
      { key: 'habit', sortable: true, headerCellClass: 'headerCell', name: 'Habit', width: 200, resizable: true },
      { key: 'action', sortable: true, headerCellClass: 'headerCell', cellClass: 'cellCenterAlign',  name: 'Type', width: 80, resizable: true },
      { key: 'journal',  sortable: true, headerCellClass: 'headerCellLeftAlign', name: 'Journal', width: 250, resizable: true },
    ];

    var dataGridElement = (<DataGrid className='rdg-light' style={{height: '100%', fontSize: 12 }} onSortColumnsChange={this.setSortColumns} columns={columns} rows={this.state.rows} />);

    return <div className='dataGridContainer'>{dataGridElement}<ReportExportButtons dataGrid={dataGridElement} fileName='lasoo_activity'/></div>;
  }
}

export default ActivityDataGrid; 

