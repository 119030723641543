import React, { Component } from 'react';

import Button from './Button';

const INITIAL_STATE = {
};

class EnabledUsersSelectorButton extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onClick = () => {
    if (this.props.onChange)
      this.props.onChange()
  }

  getButtonName = () => {
    if (this.props.enabledUsersOnly)
      return "Enabled Users Only"
    else
      return "Enabled and Disabled Users"
  }

  render = () => {
    return (
      <div>
        <div className="enabledUsersSelectorButton"><Button type='secondary' size='small' onClick={this.onClick} action={this.getButtonName()}/></div>
      </div>
    )
  }
}

export default EnabledUsersSelectorButton; 

