import React, { Component } from 'react';

import LoadingSpinner from './LoadingSpinner'
import UsersMostHabitsChart from './UsersMostHabitsChart'
import UsersDataGrid from './UsersDataGrid'
import UsersEngagementChart from './UsersEngagementChart'

const INITIAL_STATE = { };

class UsersReport extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };

    // https://us-central1-lasoo-d9fad.cloudfunctions.net/getUsersReport?team=
  }
  
  checkDataLoaded = (force) => {
    if (!this.state.dataLoaded || force) {
      var xmlHttp = new XMLHttpRequest();
      xmlHttp.onreadystatechange = () => { 
          if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
            var rows = JSON.parse(xmlHttp.responseText);
            this.setState({ dataLoaded : true, rows : rows })
          }
      }
      
      var url = "https://us-central1-lasoo-d9fad.cloudfunctions.net/getUsersReport?team=" + this.props.team;

      if (this.props.circles && this.props.circles.length > 0) {
        url += "&circles=" + this.props.circles.join(',')
      }
      if (this.props.enabledUsersOnly) {
        url += "&enabledUsersOnly=true";
      }

      console.log("URL = " + url)

      xmlHttp.open("GET", url, true); // true for asynchronous 
      xmlHttp.send(null);
    }
  }

  componentWillMount = () => {
    this.checkDataLoaded(false);
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (JSON.stringify(this.props.circles) !== JSON.stringify(prevProps.circles) ||
        JSON.stringify(this.props.enabledUsersOnly) !== JSON.stringify(prevProps.enabledUsersOnly)) {
      this.setState({
        dataLoaded: false
      })
      this.checkDataLoaded(true);
    }
  }

  componentWillUnmount() {
  }

  render = () => {

    if (!this.state.dataLoaded)
      return <LoadingSpinner/> 
    else {
      return <table width='100%'>
        <tr><td width='50%'><UsersEngagementChart rows={this.state.rows}/></td>
            <td width='50%' style={{ 'vertical-align': 'top'}}><UsersMostHabitsChart  rows={this.state.rows}/></td></tr>
        
        <tr><td colspan='2'><UsersDataGrid rows={this.state.rows}/></td></tr>
      </table>
    }
  }
}

export default UsersReport; 

