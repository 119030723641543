import React, { Component } from 'react';

import Button from './Button';
import DataGrid, { SelectCellFormatter } from 'react-data-grid';
import RowComparator from './RowComparator'

import { Navigate } from "react-router-dom";

const defaultSortColumns = [ { columnKey: 'name', direction: 'ASC' } ];
const calendarDateFormat = 'YYYY-MM-DD';

class NudgesList extends Component {
  constructor(props) {
    super(props);

    this.defaultRowComparator = new RowComparator(defaultSortColumns);
    this.state = { 
      'rows' : this.getRows(this.props.team)
    };
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (JSON.stringify(this.props.enabledUsersOnly) !== JSON.stringify(prevProps.enabledUsersOnly)) {
      this.setState({ 'rows' : this.getRows(this.props.team) });
    }
  }

  getRows = (team) => {
    if (!team.nudges)
      return [];

    var rows = []

    for (var nudgeId of Object.keys(team.nudges)) {
        rows.push(this.getRow(nudgeId, team)) 
    }

    rows.sort(this.defaultRowComparator.compare)
    return rows;
  }

  isContentAdmin = (memberEmail, team) => {
    return team['content-admin'] && team['content-admin'].indexOf(memberEmail) >= 0;
  }

  isTeamAdmin = (memberEmail, team) => {
    return team['team-admin'] && team['team-admin'].indexOf(memberEmail) >= 0;
  }

  getRow = (nudgeId, team) => {
    var row = {
      id : nudgeId
    }

    row.name = team.nudges[nudgeId].name 
    row.state = team.nudges[nudgeId].state 
    row.delay = team.nudges[nudgeId].delay 
    row.subject = team.nudges[nudgeId]['email-subject'].substring(0, 40) + "..."
    row.message = team.nudges[nudgeId]['email-template'].substring(0, 40) + "..."
    row.enabled = team.nudges[nudgeId].enabled ? "Yes" : "No"

    return row
  }
  
  setSortColumns = (sortColumnArray) => {
    var sortColumns = sortColumnArray.concat(defaultSortColumns)
    var rowComparator = new RowComparator(sortColumns)

    this.state.rows.sort(rowComparator.compare)
    this.setState({ 'rows' : this.state.rows })

    return true;
  }

  addClicked = () => {
    this.setState({ "navigateUrl": "/add-nudge" })
  }

  editClicked = (nudgeId) => {
    this.setState({ "navigateUrl": "/edit-nudge/" + nudgeId })
  }

  testClicked = (nudgeId) => {
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = () => { 
        if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
          alert("Test nudge sent")
        }
    }
    var url = "https://us-central1-lasoo-d9fad.cloudfunctions.net/testNudge?team=" + this.props.teamId + "&nudge=" + nudgeId + "&user=" + this.props.firebase.user.uid;
    xmlHttp.open("GET", url, true); // true for asynchronous 
    xmlHttp.send(null);
  }

  renderDataGrid = () => {
    var that = this;

    const columns = [
      { key: 'name', name: 'Name', sortable: true, width: 240, headerCellClass: 'headerCell', resizable: true },
      { key: 'state', name: 'State', sortable: true, width: 80, headerCellClass: 'headerCell', cellClass: 'cellCenterAlign', resizable: true },
      { key: 'delay', name: 'Delay', sortable: true, width: 120, headerCellClass: 'headerCell', cellClass: 'cellCenterAlign', resizable: true,
        formatter({ row, onRowChange, isCellSelected }) {
          return <div>
            {row.delay} days
          </div>
        }
      },
      { key: 'subject', name: 'Subject', sortable: true, width: 260, headerCellClass: 'headerCell', resizable: true },
      { key: 'enabled', name: 'Enabled', sortable: true, width: 80, headerCellClass: 'headerCell', cellClass: 'cellCenterAlign', resizable: true },

      { key: '', name: '', sortable: true, width: 80, headerCellClass: 'headerCell', cellClass: 'cellCenterAlign', resizable: true,
      formatter({ row, onRowChange, isCellSelected }) {
        return <div>
          <Button action='Test' type='secondary' size='small' onClick={() => that.testClicked(row.id)}>Test</Button>
        </div>
      }
     }, 
      { key: '', name: '', sortable: true, width: 80, headerCellClass: 'headerCell', cellClass: 'cellCenterAlign', resizable: true,
        formatter({ row, onRowChange, isCellSelected }) {
          return <div>
            <Button action='Edit' type='secondary' size='small' onClick={() => that.editClicked(row.id)}>Edit</Button>
          </div>
        }
      } 
    ];

    return (<div className='dataGridContainer'><DataGrid className='rdg-light' style={{height: '100%', fontSize: 12 }} onSortColumnsChange={this.setSortColumns} columns={columns} onRowsChange={this.onRowsChange} rows={this.state.rows} /></div>);
  }

  render = () => {
    if (this.state.navigateUrl)
      return (<Navigate to={this.state.navigateUrl} replace={true} />)

    var elements = [];

    if (this.state.rows.length > 0) {
      elements.push(this.renderDataGrid())
    }
    else {
      elements.push(<p>No nudges found</p>)
    }

    elements.push(<Button type='secondary' size='small' action='Add Nudge' onClick={this.addClicked}/>)

    return elements;
  }
}

export default NudgesList; 

