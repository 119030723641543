import React, { Component } from 'react';

import Button from './Button';
import Popup from 'reactjs-popup';
import PopupHeader from './PopupHeader';

import Select from "react-select";

import 'reactjs-popup/dist/index.css';

class SelectHabitPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      habit: (this.props.habit) ? this.props.habit : null,
      circles: (this.props.circles && this.props.circles.length > 0) ? this.props.circles.split(",") : []
    }
  }

  okButtonClicked = () => {
    if (this.props.onSelect)
      this.props.onSelect(this.props.index, this.state.habit, this.state.circles)
  }

  onCancel = () => {
    if (this.props.onCancel)
      this.props.onCancel();
  }

  habitChanged = (event) => {
    this.setState({ habit: event.value });
  }

  renderHabitSelect = () => {
    var options = []
    var defaultValue = null;

    if (this.props.habits) {
      Object.keys(this.props.habits).forEach(habitId => {
        if (this.props.habits[habitId].visible)
          options.push({ value: habitId, label: this.props.habits[habitId].name })
      })

      options.sort((o1, o2) => {
        if (o1.label < o2.label) return -1;
        if (o1.label > o2.label) return 1;
        return 0;
      });

      if (this.state.habit) {
        defaultValue = options.filter(option => option.value === this.state.habit)
      }
     }

    return <div className='selectHabitPopupSelect'><Select name="habit" defaultValue={defaultValue} options={options} onChange = {this.habitChanged}/></div>
  }

  circlesChanged = (event) => {
    this.setState({ 
      circles: event.map((item) => item.value)
    })
  }

  renderCirclesSelect = () => {
    var options = []
    var defaultValues = [];

    if (this.props.habits) {
      Object.keys(this.props.allCircles).forEach(circleId => {
        var option = { value: circleId, label: this.props.allCircles[circleId].name }
          
        options.push(option)
        if (this.state.circles.indexOf(circleId) >= 0)
          defaultValues.push(option)
      })

      options.sort((o1, o2) => {
        if (o1.label < o2.label) return -1;
        if (o1.label > o2.label) return 1;
        return 0;
      });
     }

    return <div className='selectHabitPopupSelect'>
      <Select name="circles" isMulti defaultValue={defaultValues} options={options} onChange={this.circlesChanged}/>
    </div>
  }

  render = () => {
    return <div>
        { 
          <Popup open="true" closeOnDocumentClick className='select-habit-popup' onClose={this.onCancel}>
                <PopupHeader title='Select Habit'/>

                <div className='sectionTitle'>HABIT</div>
                { this.renderHabitSelect() }
                <div className='sectionTitle'>CIRCLES</div>
                { this.renderCirclesSelect() }

                <div className='selectHabitPopupOKButton'>
                  <Button type='primary' size="medium" action="OK" onClick={this.okButtonClicked}/>
                </div>
          </Popup>
        }
    </div>;
  }
};

export default SelectHabitPopup;
