import React, { Component } from 'react';

import Button from './Button';
import CircleSelectorPopup from './CircleSelectorPopup';

const INITIAL_STATE = {
  showPopup: false,
  selectedCircles : []
};

class CirclesSelectorButton extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onClick = () => {
    this.setState({
      showPopup: true
    })
  }

  onChange = (selectedCircles) => {
    if (this.props.onChange)
      this.props.onChange(selectedCircles)

    this.setState({
      showPopup: false,
      selectedCircles: selectedCircles
    })
  }

  onClose = () => {
    this.setState({
      showPopup: false
    })
  }

  getButtonName = () => {
    if (this.state.selectedCircles.length === 0)
      return "All Circles"
    else if (this.state.selectedCircles.length === 1)
      return this.props.team.circles[this.state.selectedCircles[0]].name
    else
      return this.state.selectedCircles.length + " Circles"
  }

  render = () => {
    return (
      <div>
        <div className="circleSelectorPopup"><Button type='secondary' size='small' onClick={this.onClick} action={this.getButtonName()}/></div>
      { 
        (this.state.showPopup) ? <CircleSelectorPopup team={this.props.team} onChange={this.onChange} onClose={this.onClose}/> : <div/>
      }
      </div>
    )
  }
}

export default CirclesSelectorButton; 

