import React, { Component } from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);


class GoalsCategoriesChart extends Component {
  constructor(props) {
    super(props);

    // https://us-central1-lasoo-d9fad.cloudfunctions.net/getUsersReport?team=
  }

  calculateCategoriesData = (rows) => {
    var categoryCounts = {}

    for (var row of rows) {
      if (categoryCounts[row.category])
        categoryCounts[row.category] += 1
      else  
        categoryCounts[row.category] = 1
    }

    var categoryAndCounts = [];
    for (var category of Object.keys(categoryCounts)) 
      categoryAndCounts.push({ category: category, count: categoryCounts[category]})
    
    categoryAndCounts.sort((v1, v2) => {
      if (v1.count > v2.count)
        return -1;
      else if (v1.count < v2.count)
        return 1;
      else if (v1.category < v1.category)
        return -1;
      else 
        return 1;
    })
    
    var categories = [];
    var counts = [];
    var count = 0;

    for (var categoryAndCount of categoryAndCounts) {
      if (count < 4) {
        categories.push(categoryAndCount.category)
        counts.push(categoryAndCount.count)
        count += 1;
      }      
    }

    return {
      categories: categories,
      counts: counts
    }
  }

  render = () => {
    var categoriesData = this.calculateCategoriesData(this.props.rows)

    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
        },
        title: {
          display: true,
          text: 'HABITS BY CATEGORY',
        },
      },
    };

   const data = {
      labels: categoriesData.categories,
      datasets: [
        {
          label: '% of Goals',
          data: categoriesData.counts,
          backgroundColor: [
            'rgba(17, 32, 52, 0.5)',
            'rgba(234, 41, 82, 0.5)',
            'rgba(0, 212, 255, 0.5)',
            'rgba(150, 150, 150, 0.5)',
          ],
          borderColor: [
            '#rgba(17, 32, 52, 1)',
            'rgba(234, 41, 82, 1)',
            'rgba(0, 212, 255, 1)',
            'rgba(150, 150, 150, 1)',
          ],
          borderWidth: 1,
        },
      ],
    };
    
    return <div className='goalsCategoriesChart'><Doughnut options={options} data={data} /></div>
  }
}

export default GoalsCategoriesChart; 

