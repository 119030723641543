import React, { Component } from 'react';

import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import AppSidebar from './AppSidebar';
import WelcomeForm from './WelcomeForm'
import Button from './Button';
import LoadingSpinner from './LoadingSpinner';

import SelectHabitPopup from './SelectHabitPopup'

import { ref, child, get } from "firebase/database";
import { doc, updateDoc, getDoc } from "firebase/firestore";

import Select from "react-select";
import { toHaveAccessibleDescription } from '@testing-library/jest-dom/dist/matchers';

const INITIAL_STATE = {
  dataLoaded: false,
  teamId: "",
  teamName: "",
  objectiveName: "",
  programType: "performance-management",
  allowChallenges: false,
  disableTeamVisibility: false,
  recommendedHabits: [],
  sendWeeklyDigest: false,
  sendManagerSummary: false,
  sendHabitOfTheWeek: false,
  showSelectHabitPopup: false,
  selectHabitPopupIndex: null,
  selectHabitPopupHabit: null,
  selectHabitPopupCircles: null,
  circles : {},
  showPoints: false,

  teamDisabled: false
};

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  componentWillMount = () => {
    this.checkDataLoaded();
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    this.checkDataLoaded();
  }

  componentWillUnmount() {
  }

  isAdmin = (user, team) => {
    if (user.email === 'antony@lasoohabits.com' || user.email === 'g.kid@icloud.com' || user.email === 'sally@lasoohabits.com' || user.email === 'keith.abbott@adlerianconsulting.com.au' || user.email === 'david.royle@adlerianconsulting.com.au')
      return true; 

    if (!team['team-admin'])
      return false;

    return team['team-admin'].indexOf(user.email) >= 0;
  }

  checkDataLoaded = () => {
    var data = null;

    if (this.props.firebase.user && !this.state.dataLoaded) {
      const dbRef = ref(this.props.firebase.database);

      get(child(dbRef, 'users/' + this.props.firebase.user.uid)).then((snapshot) => {
        data = snapshot.val();

        return getDoc(doc(this.props.firebase.firestore, "teams", data.team));
      })
      .then((teamSnapshot) => {
        if (teamSnapshot.exists()) {

          var team = teamSnapshot.data()

          if (this.isAdmin(this.props.firebase.user, team)) {
            this.setState({
              data: data,
              team: team,
              teamId: data.team,
              teamName: team.name,
              objectiveName: team.settings.defaultObjective ? team.settings.defaultObjective : "",
              programType: team.settings['program-type'],
              recommendedHabits: team['recommended-habit'] ? team['recommended-habit'] : [],
              allowChallenges: team.settings.allowChallenges ? true : false,
              disableTeamVisibility: team.settings.disableTeamVisibility ? true : false,
              sendWeeklyDigest: team.settings.sendWeeklyDigest ? true : false,
              sendManagerSummary: team.settings.sendManagerSummary ? true : false,
              sendHabitOfTheWeek: team.settings.sendHabitOfTheWeek ? true : false,
              showPoints: team.settings.showPoints ? true : false,
              teamDisabled : team.settings.teamDisabled ? true : false,
              circles : team.circles ? team.circles : {},
              isAdmin: true,
              dataLoaded: true,
            });
          }
          else {
            this.setState({
              isAdmin: false,
              dataLoaded: true
            });
          }
        }
      })
    }
  }

  showSelectHabitPopup = () => {
    this.setState({ showSelectHabitPopup: true })
  }

  closeSelectHabitPopup = () => {
    this.setState({ 
      showSelectHabitPopup: false,
      selectHabitPopupIndex: null,
      selectHabitPopupHabit: null,
      selectHabitPopupCircles: null   
    })
  }

  onChangeTeamName = (e) => {
    this.setState({ teamName: e.target.value })
  }

  onChangeProgramType = (option) => {
    this.setState({ programType: option.value })
  }

  onChangeAllowChallenges = (option) => {
    this.setState({ allowChallenges: option.value })
  }  

  onChangeDisableTeamVisibility = (option) => {
    this.setState({ disableTeamVisibility: option.value })
  }

  onChangeSendHabitOfTheWeek = (option) => {
    this.setState({ sendHabitOfTheWeek: option.value })
  }

  onChangeSendWeeklyDigest = (option) => {
    this.setState({ sendWeeklyDigest: option.value })
  }

  onChangeSendManagerSummary = (option) => {
    this.setState({ sendManagerSummary: option.value })
  }

  onChangeShowPoints = (option) => {
    this.setState({ showPoints: option.value })
  }

  onChangeObjectiveName = (e) => {
    this.setState({ objectiveName: e.target.value })

  }

  onChangeTeamDisabled = (option) => {
    this.setState({ teamDisabled: option.value })
  }


  updateSettings = () => {
    var teamRef = doc(this.props.firebase.firestore, "teams", this.state.teamId);

    updateDoc(teamRef, {
      name: this.state.teamName,
      "settings.program-type": this.state.programType,
      "recommended-habit": this.state.recommendedHabits,
      "settings.allowChallenges": this.state.allowChallenges,
      "settings.defaultObjective": this.state.objectiveName.trim(),
      "settings.disableTeamVisibility": this.state.disableTeamVisibility,
      "settings.sendWeeklyDigest": this.state.sendWeeklyDigest,
      "settings.sendManagerSummary": this.state.sendManagerSummary,
      "settings.sendHabitOfTheWeek": this.state.sendHabitOfTheWeek,
      "settings.showPoints": this.state.showPoints,
      "settings.teamDisabled": this.state.teamDisabled
    })
    .then(() => {
      alert("Team Updated Successfully")
    })
  }

  editRecommendedHabit = (i) => {
    this.setState({
      showSelectHabitPopup: true,
      selectHabitPopupIndex: i,
      selectHabitPopupHabit: this.state.recommendedHabits[i].id,
      selectHabitPopupCircles: this.state.recommendedHabits[i].circles      
    })
  }

  deleteRecommendedHabit = (i) => {
    var recommendedHabits = this.state.recommendedHabits;
    recommendedHabits.splice(i, 1);
    this.setState({ recommendedHabits: recommendedHabits })
  }

  addHabitClicked = () => {
    this.setState({
      showSelectHabitPopup: true,
      selectHabitPopupIndex: null
    })
  }

  renderRecommendedHabits = () => {
    var items = [];

    items.push(<div className='cardRow' onClick={this.addHabitClicked}>
      <div className='cardRowPlusIcon'>
        <div class="material-icons md-light md-28">add</div>
      </div>
      <div className='cardRowText'>Add habit</div>
    </div>)

    for (var i = 0; i < this.state.recommendedHabits.length; i++) {
        var habit = this.state.team.habits[this.state.recommendedHabits[i].id]
        var habitName = habit ? habit.name : "Unknown Habit"
        var circles = this.state.recommendedHabits[i].circles && this.state.recommendedHabits[i].circles.length > 0 ? this.state.recommendedHabits[i].circles.split(",") : []
        if (circles.length > 0) {
          habitName += ' [' + circles.length + ']'
        }

        if (habit.visible) {
          items.push(<div className='cardRow' >
            <div  onClick={this.editRecommendedHabit.bind(this,i)} className='cardRowPlusIcon'>
              <div class="material-icons md-light md-28">check_circle</div>
            </div>
            <div  onClick={this.editRecommendedHabit.bind(this,i)} className='cardRowText'>{habitName}</div>
            <div className='cardRowDeleteIcon' onClick={this.deleteRecommendedHabit.bind(this,i)}>
              <div class="material-icons md-30">delete_outline</div>
            </div>
          </div>)
        }
    }
    
    return <div className='settingsRecommendHabitsCard'>
      {items}
    </div>

  }

  onSelectHabit = (index, habitId, circles) => {
    if (habitId) {
      var recommendedHabits = this.state.recommendedHabits;
      if (index === null)
        recommendedHabits.push({ id: habitId, circles: "" } );
      else 
        recommendedHabits[index] = { id: habitId, circles: circles.join(",") };

      this.setState({ recommendedHabits: recommendedHabits })
    }
    this.closeSelectHabitPopup();
  }

  renderSettings = () => {
    if (!this.state.dataLoaded) {
      return <LoadingSpinner/>
    }
    else if (!this.state.isAdmin) {
      return <div>Only team admins have access to settings</div>
    }
    else {
      var programTypeOptions = [
        { value: 'coaching-and-mentoring', label: 'Coaching & Mentoring' },
        { value: 'l-and-d', label: 'Learning & Development' },
        { value: 'performance-management', label: 'Performance Management' }
      ];

      var programTypeDefaultValue = programTypeOptions.filter(option => option.value === this.state.programType)
      
      var allowChallengesOptions = [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
      ];

      var allowChallengesDefaultValue = allowChallengesOptions.filter(option => option.value === this.state.allowChallenges)
    
      var disableTeamVisibilityOptions = [
        { value: false, label: 'No' },
        { value: true, label: 'Yes' }
      ];      

      var disableTeamVisibilityDefaultValue = disableTeamVisibilityOptions.filter(option => option.value === this.state.disableTeamVisibility)
    
      var sendWeeklyDigestOptions = [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
      ];

      var sendWeeklyDigestDefaultValue = sendWeeklyDigestOptions.filter(option => option.value === this.state.sendWeeklyDigest)
 
      var sendManagerSummaryOptions = [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
      ];

      var sendManagerSummaryDefaultValue = sendManagerSummaryOptions.filter(option => option.value === this.state.sendManagerSummary)
 
      var sendHabitOfTheWeekOptions = [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
      ];

      var sendHabitOfTheWeekDefaultValue = sendHabitOfTheWeekOptions.filter(option => option.value === this.state.sendHabitOfTheWeek)
 
      var showPointsOptions = [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
      ];

      var showPointsDefaultValue = showPointsOptions.filter(option => option.value === this.state.showPoints)

      var sendHabitOfTheWeekDefaultValue = sendHabitOfTheWeekOptions.filter(option => option.value === this.state.sendHabitOfTheWeek)
 
      var teamDisabledOptions = [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
      ];

      var teamDisabledDefaultValue = teamDisabledOptions.filter(option => option.value === this.state.teamDisabled)
 

      return <div className='settingsForm'>
        <div className='sectionTitle'>TEAM NAME</div>
        <input class="settingsTeamName" onChange={this.onChangeTeamName} value={this.state.teamName}/>
        <div className='sectionTitle'>PROGRAM TYPE</div>
        <Select name="programType" onChange={this.onChangeProgramType} defaultValue={programTypeDefaultValue} options={programTypeOptions}/>
        <div className='sectionTitle'>ALLOW CHALLENGES</div>
        <Select name="allowChallenges" onChange={this.onChangeAllowChallenges} defaultValue={allowChallengesDefaultValue} options={allowChallengesOptions}/>
        <div className='sectionTitle'>DEFAULT OBJECTIVE NAME (FOR NEW USERS)</div>
        <input class="settingsObjectiveName" onChange={this.onChangeObjectiveName} value={this.state.objectiveName}/>

        <div className='sectionTitle'>DISABLE TEAM VISIBILITY</div>
        <Select name="disableTeamVisibility" onChange={this.onChangeDisableTeamVisibility} defaultValue={disableTeamVisibilityDefaultValue} options={disableTeamVisibilityOptions}/>

        <div className='sectionTitle'>SEND HABIT OF THE WEEK</div>
        <Select name="sendWeeklyDigest" onChange={this.onChangeSendHabitOfTheWeek} defaultValue={sendHabitOfTheWeekDefaultValue} options={sendHabitOfTheWeekOptions}/>

        <div className='sectionTitle'>SEND WEEKLY DIGEST EMAIL</div>
        <Select name="sendWeeklyDigest" onChange={this.onChangeSendWeeklyDigest} defaultValue={sendWeeklyDigestDefaultValue} options={sendWeeklyDigestOptions}/>
      
        <div className='sectionTitle'>SEND MANAGER DIGEST EMAIL</div>
        <Select name="sendManagerSummary" onChange={this.onChangeSendManagerSummary} defaultValue={sendManagerSummaryDefaultValue} options={sendManagerSummaryOptions}/>
   
        <div className='sectionTitle'>SHOW POINTS/LEVELS</div>
        <Select name="showPoints" onChange={this.onChangeShowPoints} defaultValue={showPointsDefaultValue} options={showPointsOptions}/>
   

        <div className='sectionTitle'>TEAM DISABLED</div>
        <Select name="teamDisabled" onChange={this.onChangeTeamDisabled} defaultValue={teamDisabledDefaultValue} options={teamDisabledOptions}/>
   
        <div className='sectionTitle'>RECOMMENDED HABITS</div>
        { this.renderRecommendedHabits() }   

        <p><Button type='primary' size='medium' onClick={() => this.updateSettings()} action='Update Settings'/></p>
      </div>
    }
  }

  render = () => {
    var firebase = this.props.firebase;
    var userLoaded = firebase.initialised;
    var user = this.props.firebase.user

    return (
      <div className='App-pageframeOuter'>
        <div className='App-pageframeInner'>
          <AppHeader title={this.state.teamName} firebase={firebase} />
          <AppSidebar tab="Settings" firebase={firebase}/>
          <div className='App-content'>
            <div className='App-content-column'>
            {
              !userLoaded 
                ?
                <div>
                   
                </div>
                :
              user
                ? 
                 this.renderSettings()
                : 
                <WelcomeForm/>
            }
           </div>
          </div>

          
          { this.state.showSelectHabitPopup && <SelectHabitPopup index={this.state.selectHabitPopupIndex} habit={this.state.selectHabitPopupHabit} allCircles={this.state.circles} circles={this.state.selectHabitPopupCircles} habits={this.state.team ? this.state.team.habits : null} onSelect={this.onSelectHabit} onCancel={this.closeSelectHabitPopup} /> }

          <AppFooter tab="Settings"/>
        </div>
      </div>
    )
  }
}

export default Settings; 

