import React, { Component } from 'react';

import Button from './Button';
import Popup from 'reactjs-popup';
import PopupHeader from './PopupHeader';

import 'reactjs-popup/dist/index.css';

class TeamCreatePopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: "",
      contact: ""
    }
  }

  okButtonClicked = () => {
    if (this.state.name.trim().length === 0 || this.state.contact.trim().length === 0)
      return

    if (this.props.onCreate)
      this.props.onCreate(this.state.name, this.state.contact)
  }

  onCancel = () => {
    if (this.props.onCancel)
      this.props.onCancel();
  }

  handleNameChange = (event) => {
    this.setState({ name: event.target.value });
  }

  handleContactChange = (event) => {
    this.setState({ contact: event.target.value });
  }

  render = () => {
    return <div>
        { 
          <Popup open="true" closeOnDocumentClick className='team-create-popup' onClose={this.onCancel}>
                <PopupHeader title='Create Team'/>

                <div className="teamCreatePopupNameLabel">Team Name</div>
                <input type='text' className="teamCreatePopupNameInput" onChange={ (event) => { this.handleNameChange(event); }} value={this.state.name}/>

                <div className="teamCreatePopupContactLabel">Contact Name</div>
                <input type='text' className="teamCreatePopupContactInput" onChange={ (event) => { this.handleContactChange(event); }} value={this.state.contact}/>

                <div className='teamCreatePopupOKButton'>
                  <Button type='primary' size="medium" action="Create" onClick={this.okButtonClicked}/>
                </div>
          </Popup>
        }
    </div>;
  }
};

export default TeamCreatePopup;
