import React, { Component } from 'react';

class LoadingSpinner extends Component {

  constructor(props) {
    super(props);
  } 

  render = () => {
    return ( 
      <div className='loadingSpinnerContainer'>
        <img className='loadingSpinnerImage' src="/loading_spinner.gif"/>        
      </div>
    )
  }
}

export default LoadingSpinner;