import React, { Component } from 'react';

import { Bar } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

class ActivityTopActiveUsersChart extends Component {
  constructor(props) {
    super(props);

    // https://us-central1-lasoo-d9fad.cloudfunctions.net/getUsersReport?team=
  }

  calclulateTopActiveUsers = (rows) => {
    var userActivityCount = {};
    for (var row of rows) {
      if (row.action === 'Complete') {
        if (userActivityCount[row.name])
          userActivityCount[row.name] += 1;
        else 
          userActivityCount[row.name] = 1;
      }
    }

    var usersAndCounts = [];
  
    for (var user of Object.keys(userActivityCount)) {
      usersAndCounts.push({
        user: user,
        count: userActivityCount[user]
      })
    }

    usersAndCounts.sort((v1, v2) => {
      if (v1.count < v2.count)
        return 1;
      else if (v1.count > v2.count)
        return -1;
      else if (v1.user < v2.user) 
        return -1;
      else 
        return 1;
    })

    var users = [];
    var counts = [];
    var count = 0;

    for (var usersAndCount of usersAndCounts) {
      if (count < 9) {
        users.push(usersAndCount.user)
        counts.push(usersAndCount.count)
        count += 1;
      }
    }

    return {
      users: users,
      counts: counts
    }
  }


  render = () => {
    var topActiveUsers = this.calclulateTopActiveUsers(this.props.rows);

    const options = {
      indexAxis: 'y',
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'right',
        },
        title: {
          display: true,
          text: 'TOP ACTIVE USERS',
        },
      },
    };

    const dataHorBar = {
      labels: topActiveUsers.users,
      datasets: [
        {
          label: 'Active users',
          backgroundColor: 'rgba(234, 41, 82, 0.5)',
          borderColor: '#ea2952',

          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255,99,132,0.4)',
          hoverBorderColor: 'rgba(255,99,132,1)',
          data: topActiveUsers.counts
        }
      ]
    };

    return <Bar options={options} data={dataHorBar} />
  }
}

export default ActivityTopActiveUsersChart; 

