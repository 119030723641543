import React, { Component } from 'react';

import Button from './Button';

import Popup from 'reactjs-popup';
import PopupHeader from './PopupHeader';

import 'reactjs-popup/dist/index.css';

class CircleSelectorPopup extends Component {

  constructor(props) {
      super(props);
      this.state = {
        selectedCircles: (this.props.selectedCircles) ? this.props.selectedCircles : [],
        originallySelectedCircles: (this.props.selectedCircles) ? this.props.selectedCircles : []
      };
  } 

  getCirclesOrdered = (team) => {
    var circles = []

    if (team.circles) {
      for (var circleId in team.circles) {
        circles.push({ 
          id: circleId,
          name: team.circles[circleId].name
        })
      }
    }

    circles.sort((c1, c2) => {
      if (c1.name < c2.name)
        return -1;
      else if (c1.name > c2.name)
        return 1;
      else
        return 0;
    });

    return circles;
  } 

  circleClicked = (circleId) => {
    if (this.state.selectedCircles.indexOf(circleId) < 0) {
      this.state.selectedCircles.push(circleId);
      this.state.selectedCircles.sort();

      this.setState({ selectedCircles : this.state.selectedCircles })
    }
    else {
      var filtered = this.state.selectedCircles.filter((value) => { 
        return value !== circleId;
      });
      this.setState({ selectedCircles : filtered })
    }
  }

  okButtonClicked = () => {
    if (this.props.onChange)
      this.props.onChange(this.state.selectedCircles)
  }

  renderCircles = () => {
    var items = [];

    var circles = this.getCirclesOrdered(this.props.team)

    for (var i = 0; i < circles.length; i++) {
      var circle = circles[i]
      items.push(<div onClick={this.circleClicked.bind(this, circle.id)} className='circlesPopupRow'>
        { (this.state.selectedCircles.indexOf(circle.id) >= 0) ?
          <img className='circlesPopupRowCheckbox' src="/icons/check.png"/>
        : <div/> }
      
        <div className='circlesPopupRowName'>{circle.name}</div>
      </div>)
    } 


    return <div className='circlesPopupContainer'>{items}</div>;
  }

  onClose = () => {
    if (this.props.onClose)
      this.props.onClose(this.state.originallySelectedCircles)
  }

  render = () => {
    return <div>
        { 
          <Popup open="true" closeOnDocumentClick className='circles-popup' onClose={this.onClose}>
                <PopupHeader title='Select Circles'/>
                { this.renderCircles() }
                <div className='circlesPopupOKButton'>
                  <Button type='primary' size="medium" action="OK" onClick={this.okButtonClicked}/>
                </div>
          </Popup>
        }
    </div>;
  }
};

export default CircleSelectorPopup;
