import React, { Component } from 'react';

import Button from './Button';
import Popup from 'reactjs-popup';
import PopupHeader from './PopupHeader';

import 'reactjs-popup/dist/index.css';

class CircleCreatePopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: "",
      description: ""
    }
  }

  okButtonClicked = () => {
    if (this.state.name.trim().length === 0)
      return

    if (this.props.onCreate)
      this.props.onCreate(this.state.name, this.state.description)
  }

  onCancel = () => {
    if (this.props.onCancel)
      this.props.onCancel();
  }

  handleNameChange = (event) => {
    this.setState({ name: event.target.value });
  }

  handleDescriptionChange = (event) => {
    this.setState({ description: event.target.value });
  }

  render = () => {
    return <div>
        { 
          <Popup open="true" closeOnDocumentClick className='circle-create-popup' onClose={this.onCancel}>
                <PopupHeader title='Create Circle'/>

                <div className="circleCreatePopupNameLabel">Name</div>
                <input type='text' className="circleCreatePopupNameInput" onChange={ (event) => { this.handleNameChange(event); }} value={this.state.name}/>

                <div className="circleCreatePopupDescriptionLabel">Description</div>
                <textarea className="circleCreatePopupDescriptionInput" onChange={ (event) => { this.handleDescriptionChange(event); }} value={this.state.description}/>

                <div className='circleCreatePopupOKButton'>
                  <Button type='primary' size="medium" action="OK" onClick={this.okButtonClicked}/>
                </div>
          </Popup>
        }
    </div>;
  }
};

export default CircleCreatePopup;
