import React, { Component } from 'react';

import { Navigate } from "react-router-dom";

import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import Button from './Button'

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  navigateUrl : null
};

class Login extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  signInWithGoogle = () => {
    this.props.firebase.doSignInWithGoogle()
      .then(() => {
        this.setState({ "navigateUrl": "/" })
      })
      .catch((e) => {
        alert(e)
      });
  }

  onSubmit = event => {
    const { email, password } = this.state;

    event.preventDefault();

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ "navigateUrl": "/" })
      })
      .catch(error => {
        this.setState({ error });
      });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  resetMyPassword = () => {
    this.setState({ "navigateUrl": "/reset-my-password" })
  }

  render = () => {
    var firebase = this.props.firebase;

    if (this.state.navigateUrl)
      return (<Navigate to={this.state.navigateUrl}  replace={true} />)

    const {
      email,
      password,
      error,
    } = this.state;

    const isInvalid =
      password === '' ||
      email === '';
    
    return (
      <div className='App-pageframeOuter'>
        <div className='App-pageframeInner'>
          <AppHeader firebase={firebase} />
          <AppSidebar tab="Dashboard" firebase={firebase}/>
   
          <div className='App-content'>
            <div className='App-content-column-narrow'>
              <div>

                <p>
                <input
                  name="email"
                  value={email}
                  onChange={this.onChange}
                  type="text"
                  placeholder="Email"
                />

                <input
                  name="password"
                  value={password}
                  onChange={this.onChange}
                  type="password"
                  placeholder="Password"
                />
                </p>

                <Button action="Login" size='medium' type='primary' disabled={isInvalid} onClick={this.onSubmit} action="Login"/>

                <p className='loginTroubleLoggingIn'>Trouble logging in? <span className='resetMyPasswordLink' onClick={this.resetMyPassword}>Reset your password</span></p>

                {error && <p>{error.message}</p>}

                { false &&
                  <p>
                    <div className='buttonContainer'>
                      <button type="button" className='btn btn-google' onClick={this.signInWithGoogle}>
                        <img src="google_logo.png" width="18" height="18" />&nbsp;&nbsp;Sign in with Google
                      </button>
                    </div>
                  </p>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;

