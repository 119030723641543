class RowComparator  {
  constructor(fieldList) {
    this.fieldList = fieldList;
  }

  compare = (row1, row2) => {
    for (var field of this.fieldList) {
      var columnKey = field.columnKey;
      var direction = field.direction;
      
      if (row1[columnKey] < row2[columnKey])
        return (direction === 'ASC') ? -1 : 1;
      if (row1[columnKey] > row2[columnKey])
        return (direction === 'ASC') ? 1 : -1;
    }
    return 0;
  }
}

export default RowComparator;

